import Tooltip from '@gain/components/tooltip'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import { uniq } from 'lodash'
import { useMemo } from 'react'

import { AssetsPerRegion } from './assets-per-region'

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0, 0.75 / 2),
  '&:first-child': {
    marginLeft: 0,
  },
  '&:last-child': {
    marginRight: 0,
  },
}))

export interface AssetRegionsTableCellProps {
  regionNames: string[]
  disableCounts?: boolean
  dense?: boolean
  maxItems?: number
}

export function AssetRegionsTableCell({
  regionNames,
  disableCounts = false,
  dense,
  maxItems = 3,
}: AssetRegionsTableCellProps) {
  const regionCountMap = useMemo(
    () =>
      regionNames.reduce(
        (acc, current) => ({
          ...acc,
          [current]: (acc[current] || 0) + 1,
        }),
        {} as Record<string, number>
      ),
    [regionNames]
  )

  const sortedRegions = useMemo(
    () => uniq(regionNames).sort((a, b) => regionCountMap[b] - regionCountMap[a]),
    [regionNames, regionCountMap]
  )

  const rest = sortedRegions.slice(maxItems)

  if (sortedRegions.length === 0) {
    return <>-</>
  }

  return (
    <>
      {sortedRegions.slice(0, maxItems).map((region, index) => (
        <Tooltip
          key={index}
          title={
            <AssetsPerRegion
              disableCounts={disableCounts}
              regionCounts={[regionCountMap[region]]}
              regionNames={[region]}
            />
          }
          variant={'preview'}
          disableInteractive>
          <StyledChip
            label={region}
            size={dense ? 'small' : 'medium'}
          />
        </Tooltip>
      ))}
      {rest.length > 0 && (
        <Tooltip
          title={
            <AssetsPerRegion
              disableCounts={disableCounts}
              regionCounts={rest.map((region) => regionCountMap[region])}
              regionNames={rest}
            />
          }
          variant={'preview'}
          disableInteractive>
          <StyledChip
            label={`+${rest.length}`}
            size={dense ? 'small' : 'medium'}
            variant={'outlined'}
          />
        </Tooltip>
      )}
    </>
  )
}
