import { ShiftIcon } from '@gain/components/icons'
import Snackbar from '@gain/components/snackbar'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { range } from 'lodash'
import { useSnackbar } from 'notistack'
import { ChangeEvent, MouseEvent, useMemo, useRef } from 'react'

import Kbd from '../kdb'
import { SelectionChangeEvent } from './virtual-table-model'

/**
 * useCheckboxSelection returns utility functions to manage and display
 * checkbox list state.
 */
export default function useCheckboxSelection(
  selectionModel: number[],
  maxAutoSelectableRows: number,
  onSelectionChange: (event: SelectionChangeEvent) => void
) {
  const lastClickedCheckboxIndexRef = useRef<null | number>(null)
  const { enqueueSnackbar } = useSnackbar()

  return useMemo(() => {
    const autoSelectedRows = selectionModel.filter((index) => index < maxAutoSelectableRows).length

    return {
      isAllSelected: autoSelectedRows > 0 && autoSelectedRows === maxAutoSelectableRows,
      isIndeterminate: autoSelectedRows > 0 && autoSelectedRows < maxAutoSelectableRows,
      toggleSelection: (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
          const indices = range(0, maxAutoSelectableRows)
          const selected = indices.filter((index) => !selectionModel.includes(index))
          onSelectionChange({ selected })
          lastClickedCheckboxIndexRef.current = maxAutoSelectableRows

          enqueueSnackbar(undefined, {
            key: 'checkbox-selection-info-snackbar',
            preventDuplicate: true,
            content: (snackbarId) => (
              <Snackbar
                icon={
                  <Chip
                    label={'Tip'}
                    sx={{ backgroundColor: 'info.main', color: 'white' }}
                  />
                }
                id={snackbarId}
                message={
                  <Stack
                    alignItems={'center'}
                    direction={'row'}
                    gap={1}>
                    Hold
                    <Kbd
                      icon={ShiftIcon}
                      label={'Shift'}
                    />
                    + <Kbd label={'Click'} /> to bulk select an unlimited number of rows
                  </Stack>
                }
              />
            ),
          })
        } else {
          onSelectionChange({ deselected: selectionModel })
          lastClickedCheckboxIndexRef.current = 0
        }
      },
      toggleCheckbox: (rowIndex: number) => (event: MouseEvent) => {
        // To prevent activating the href="" of a parent element in Firefox,
        // preventDefault() must be used within the onClick event rather than
        // the onChange event.
        event.stopPropagation()
        event.preventDefault()

        const checked = selectionModel?.includes(rowIndex)

        let rowIndexes = [rowIndex]

        // When the user shift clicks a checkbox, apply checkbox status
        // to all checkboxes between and including the last clicked
        // checkbox and the currently clicked checkbox
        if (event.shiftKey && lastClickedCheckboxIndexRef.current !== null) {
          let start = lastClickedCheckboxIndexRef.current
          let end = rowIndex

          if (rowIndex < lastClickedCheckboxIndexRef.current) {
            start = rowIndex
            end = lastClickedCheckboxIndexRef.current
          }

          rowIndexes = range(start, end + 1)
        }

        onSelectionChange({
          selected: !checked ? rowIndexes : undefined,
          deselected: checked ? rowIndexes : undefined,
        })

        lastClickedCheckboxIndexRef.current = rowIndex
      },
    }
  }, [enqueueSnackbar, maxAutoSelectableRows, onSelectionChange, selectionModel])
}
