import { useAssetListItem } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { Asset, DealListItem } from '@gain/rpc/app-model'
import { ListSort } from '@gain/rpc/list-model'
import { listFilter, listSort, serializeListSortArray } from '@gain/rpc/utils'
import { buildAssetFileName } from '@gain/utils/asset'
import Grid from '@mui/material/Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import SourcesList from '../../../features/source/source-list'
import { useAssetPageParams } from '../../utils'
import { useAssetSources } from '../route-asset-hooks'
import { useAssetLegalEntities } from '../use-asset-legal-entities'
import {
  useAcquisitionsAndDivestmentsDeals,
  useAssetDealHistoryDeals,
} from './asset-ownership.hooks'
import CardAssetDeals from './card-asset-deals'
import CardAssetFunding from './card-asset-funding'
import { hasFundingMetrics } from './card-asset-funding/card-asset-funding'
import CardAssetHistory from './card-asset-history'
import CardAssetShareholders from './card-asset-shareholders'
import { useCurrentShareholders } from './card-asset-shareholders/asset-shareholder-util'
import LegalStructuresAndShareholdersCard from './legal-structures-and-shareholders-card/legal-structures-and-shareholders-card'
import ManagementCard from './management-card'
import { useManagersWithInvestors } from './management-card/asset-management-util'

const dealSort: ListSort<DealListItem>[] = [
  listSort('announcementDateYear', 'desc'),
  listSort('announcementDateMonth', 'desc'),
]

interface AssetOwnershipProps {
  asset: Asset
}

export default function AssetOwnership({ asset }: AssetOwnershipProps) {
  const params = useAssetPageParams()
  const assetListItem = useAssetListItem(params.id)
  const sources = useAssetSources(asset?.sources, 'background')
  const assetDealHistoryDeals = useAssetDealHistoryDeals(params.id)
  const acquisitionsAndDivestmentsDeals = useAcquisitionsAndDivestmentsDeals(params.id)

  const managers = useManagersWithInvestors(asset)
  const theme = useTheme()
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const legalEntities = useAssetLegalEntities(asset?.legalEntities || [])
  const shareholders = useCurrentShareholders(asset?.shareholders || [])
  const hasShareholders = !!shareholders.data?.length
  const hasFunding = assetListItem.data && hasFundingMetrics(assetListItem.data)

  let maxNrOfInitialShareholders = shareholders.data?.length || 0
  if (hasFunding && isLgUp) {
    maxNrOfInitialShareholders = 5
  }

  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name} - Summary</title>
      </Head>
      <Grid
        spacing={2}
        container>
        <Grid
          size={{
            lg: 6,
            xs: 12,
          }}>
          <CardAssetHistory
            history={asset.description?.history || ''}
            ownershipIsVerified={asset.generalInfo?.ownershipIsVerified}
            ownershipType={asset.generalInfo?.ownership}
          />
        </Grid>

        <Grid
          size={{
            lg: 6,
            xs: 12,
          }}>
          <ManagementCard
            managers={managers.data}
            fullHeight
          />
        </Grid>

        {hasShareholders && (
          <Grid size={12}>
            <CardAssetShareholders
              asset={asset}
              maxNrOfInitialItems={maxNrOfInitialShareholders}
              shareholders={shareholders.data}
              fullHeight
            />
          </Grid>
        )}

        {assetListItem.data && hasFunding && (
          <Grid size={12}>
            <CardAssetFunding
              asset={assetListItem.data}
              totalFunding={asset?.generalInfo?.totalFunding}
              totalFundingCurrency={asset?.generalInfo?.totalFundingCurrency}
              fullHeight
            />
          </Grid>
        )}

        {assetDealHistoryDeals.data.items.length > 0 && (
          <Grid size={12}>
            <CardAssetDeals
              deals={assetDealHistoryDeals.data.items}
              exportContainerProps={{
                params: {
                  columns: [],
                  filename: buildAssetFileName(asset, 'deals.xlsx'),
                  filter: [
                    listFilter<DealListItem>(
                      'id',
                      '=',
                      assetDealHistoryDeals.data.items.map((item) => item.id)
                    ),
                  ],
                  sort: serializeListSortArray(dealSort),
                },
              }}
              title={'Platform deal history'}
              disableDealTargetMetrics
            />
          </Grid>
        )}

        {acquisitionsAndDivestmentsDeals.data.length > 0 && (
          <Grid size={12}>
            <CardAssetDeals
              deals={acquisitionsAndDivestmentsDeals.data}
              exportContainerProps={{
                params: {
                  columns: [],
                  filename: buildAssetFileName(asset, 'acquisitions and divestments.xlsx'),
                  filter: [
                    listFilter<DealListItem>(
                      'id',
                      '=',
                      acquisitionsAndDivestmentsDeals.data.map((item) => item.id)
                    ),
                  ],
                  sort: serializeListSortArray(dealSort),
                },
              }}
              title={'Acquisitions and divestments'}
              disableDealMetrics
            />
          </Grid>
        )}

        {legalEntities.data.length > 0 && (
          <Grid size={12}>
            <LegalStructuresAndShareholdersCard legalEntities={legalEntities.data} />
          </Grid>
        )}

        {sources.length > 0 && (
          <Grid size={12}>
            <SourcesList sources={sources} />
          </Grid>
        )}
      </Grid>
    </>
  )
}
