import {
  useLeaveBookmarksFilteredList,
  useShareBookmarksFilteredList,
  useTransferBookmarksFilteredList,
  useUnshareBookmarksFilteredList,
} from '@gain/api/app/hooks'

import BookmarksShareDialog, { BookmarksShareDialogProps } from './bookmarks-share-dialog'

type ShareCustomQueryListDialogProps = Omit<
  BookmarksShareDialogProps,
  'onLeave' | 'onTransfer' | 'onUnshare' | 'onShare'
>

export function BookmarksFilteredShareDialog(props: ShareCustomQueryListDialogProps) {
  const leaveList = useLeaveBookmarksFilteredList()
  const transferList = useTransferBookmarksFilteredList()
  const shareList = useShareBookmarksFilteredList()
  const unshareList = useUnshareBookmarksFilteredList()

  return (
    <BookmarksShareDialog
      onLeave={leaveList}
      onShare={shareList}
      onTransfer={transferList}
      onUnshare={unshareList}
      {...props}
    />
  )
}
