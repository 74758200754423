import { useRpcClient } from '@gain/api/swr'
import Typography from '@gain/components/typography'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import InputLabel from '@mui/material/InputLabel'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { InferType, number, object, string } from 'yup'

import DialogHeader from '../../../common/dialog-header'
import { useTrackUpload } from '../../planhat/planhat-hooks'
import BookmarkListSelect from './bookmark-list-select'
import BookmarkListUrlImportStatusSnackbar from './bookmark-list-url-import-status-snackbar'

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}))

const MAX_URLS = 1000

function parseUrls(value: string) {
  return value.split(/[;,]?\s+/).filter((url) => url.trim())
}

const FormSchema = object({
  urls: string()
    .required('Enter at least 1 URL')
    // Add custom validator function to check the number of rows
    .test((value, context) => {
      const urls = parseUrls(value)
      if (urls.length > MAX_URLS) {
        return context.createError({
          message: `URLs entered: ${urls.length}/${MAX_URLS}`,
          path: 'urls',
        })
      }

      return true
    }),
  listId: number().required('Required field'),
})

type FormValues = InferType<typeof FormSchema>

interface BookmarksListUrlImportDialogProps extends Omit<DialogProps, 'onSubmit'> {
  open: boolean
  onClose: () => void
  listId?: number
}

/**
 * Dialog that allows the user to import URLs to a bookmark list
 */
export default function BookmarkListUrlImportDialog({
  open,
  onClose,
  listId,
}: BookmarksListUrlImportDialogProps) {
  const trackUpload = useTrackUpload()
  const snackbar = useSnackbar()
  const rpcClient = useRpcClient()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState(false)

  const form = useForm<FormValues>({
    defaultValues: {
      urls: '',
      listId,
    },
    resolver: yupResolver(FormSchema),
    mode: 'onChange',
  })
  const urls = parseUrls(form.watch('urls'))

  const handleClose = () => {
    form.reset()
    setSubmitError(false)
    setIsSubmitting(false)
    onClose()
  }

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      setIsSubmitting(true)
      setSubmitError(false)

      const taskId = await rpcClient({
        method: 'lists.customAssetListImportFromUrls',
        params: {
          customAssetListId: data.listId,
          urls: parseUrls(data.urls),
        },
      })

      const snackbarKey = `bookmark-list-url-import-loading-${taskId}`

      snackbar.enqueueSnackbar(undefined, {
        key: snackbarKey,
        content: () => (
          <BookmarkListUrlImportStatusSnackbar
            snackbarId={snackbarKey}
            taskId={taskId}
          />
        ),
        persist: true,
      })

      form.reset({
        urls: '',
        listId: listId,
      })
      handleClose()
      trackUpload('URL Import', 'bookmark')
    } catch (e) {
      setSubmitError(true)
    } finally {
      setIsSubmitting(false)
    }
  })

  return (
    <Dialog
      keepMounted={false}
      onClose={handleClose}
      open={open}>
      <form onSubmit={handleSubmit}>
        <FormProvider {...form}>
          <DialogHeader
            onCloseClick={handleClose}
            title={'Adding companies by URL'}
          />
          <StyledDialogContent>
            {/* Dialog description */}
            <Typography variant={'body2'}>
              Input URLs of companies you would like to store in a bookmark list. You can can add
              URLs on separate rows (directly paste an Excel list), or separate URLs using a space.
              Once we have finished processing this list you will be able to download an Excel
              report of matching results.
            </Typography>

            {/* URL input field */}
            <TextField
              error={!!form.formState.errors.urls}
              helperText={
                form.formState.errors?.urls?.message || `URLs entered: ${urls.length}/${MAX_URLS}`
              }
              label={'Enter URLs'}
              maxRows={5}
              minRows={5}
              placeholder={'E.g. https://gain.pro/'}
              autoFocus
              fullWidth
              multiline
              {...form.register('urls')}
            />

            {/* List selection field */}
            {!listId && (
              <div>
                <InputLabel
                  error={!!form.formState.errors?.listId}
                  id={'selectBookmarkListId'}
                  variant={'outlined'}>
                  Assign to bookmark list
                </InputLabel>

                <BookmarkListSelect name={'listId'} />
              </div>
            )}

            {/* Display async submit error */}
            {submitError && (
              <Typography
                color={'error'}
                variant={'subtitle2'}>
                Error while submitting, please try again.
              </Typography>
            )}
          </StyledDialogContent>

          <DialogActions>
            <LoadingButton
              color={'primary'}
              loading={isSubmitting}
              type={'submit'}
              variant={'contained'}
              fullWidth>
              Add companies
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </form>
    </Dialog>
  )
}
