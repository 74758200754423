import { AssetListItem, InvestorStrategyListItem } from '@gain/rpc/app-model'
import { useIsXs } from '@gain/utils/responsive'
import { styled } from '@mui/material/styles'
import { useMemo } from 'react'

import Card, { CardContent, CardHeader } from '../../../common/card/card'
import { CompanyTreeMapChart } from '../../../features/chart'
import InvestorEsgAssessmentCard from './investor-esg-assessment-card'
import InvestorInvestmentCriteriaCard from './investor-investment-criteria-card'
import { getRatingsPerStrategy } from './investor-rating-utils'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  minWidth: 0,
  maxWidth: '100%',
  width: '100%',
}))

interface RouteAssessmentProps {
  assets: AssetListItem[]
  strategies: InvestorStrategyListItem[]
}

export default function RouteAssessment({ assets, strategies }: RouteAssessmentProps) {
  const isXs = useIsXs()

  const ratingStrategies = useMemo(
    () => getRatingsPerStrategy(strategies, assets),
    [strategies, assets]
  )

  return (
    <StyledRoot>
      <Card sx={{ pb: 0 }}>
        <CardHeader
          title={'Portfolio overview'}
          absolute
        />
        <CardContent sx={{ pt: isXs ? 7 : 3 }}>
          <CompanyTreeMapChart
            assets={assets}
            height={400}
            investorStrategies={strategies}
          />
        </CardContent>
      </Card>

      <InvestorInvestmentCriteriaCard strategies={ratingStrategies} />

      <InvestorEsgAssessmentCard strategies={ratingStrategies} />
    </StyledRoot>
  )
}
