import { useListRecentVisits, useRecentlyFilteredBookmarkLists } from '@gain/api/app/hooks'
import { SearchIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import { styled } from '@mui/material/styles'
import { useMemo } from 'react'

import RecentFilterList from '../../../../features/search/recent-filter'
import RecentVisitsList from '../../../../features/search/recent-visits'

const StyledContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(1.5),
}))

const StyledCenterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: theme.spacing(1),
  paddingTop: theme.spacing(12),
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(1.5, 0, 2, 0),
}))

const StyledFade = styled(Fade)({
  flex: 1,
})

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: 40,
  height: 40,
  mb: 1,
}))

export function SearchEmpty() {
  const { lists: recentFilterLists, loading: loadingRecentFilters } =
    useRecentlyFilteredBookmarkLists()
  const { data, loading: loadingRecentVisits } = useListRecentVisits()

  const recentFilterItems = useMemo(() => {
    if (loadingRecentFilters) {
      return Array.from(new Array(4).fill(null))
    }

    return recentFilterLists ?? []
  }, [recentFilterLists, loadingRecentFilters])

  const recentVisitItems = useMemo(() => {
    if (loadingRecentVisits) {
      return Array.from(new Array(10).fill(null))
    }

    return data?.items ?? []
  }, [data?.items, loadingRecentVisits])

  return (
    <StyledContainer>
      {recentFilterItems.length > 0 || recentVisitItems.length > 0 ? (
        <>
          <RecentFilterList recentFilterLists={recentFilterItems} />

          {recentFilterItems.length > 0 && recentVisitItems.length > 0 && <StyledDivider />}

          <RecentVisitsList
            items={recentVisitItems}
            loading={loadingRecentVisits}
          />
        </>
      ) : (
        <StyledFade in>
          <StyledCenterContainer>
            <StyledSearchIcon strokeWidth={3} />
            <Typography
              color={'text.secondary'}
              variant={'body1'}>
              Search for companies, investors or industries
            </Typography>
          </StyledCenterContainer>
        </StyledFade>
      )}
    </StyledContainer>
  )
}
