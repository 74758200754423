import { useUpdateUserProfileCallback } from '@gain/api/app/hooks'
import { useUserProfileContext } from '@gain/modules/user'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import { SettingContainer } from './setting-container'
import { SettingDescription } from './setting-description'
import { SettingDivider } from './setting-divider'
import { SettingTitle } from './setting-title'

export function ListUpdates() {
  const profile = useUserProfileContext()
  const updateProfile = useUpdateUserProfileCallback()

  return (
    <SettingContainer>
      <SettingTitle>Bookmark updates</SettingTitle>
      <SettingDescription>Get notified on new matches and relevant updates</SettingDescription>
      <SettingDivider />
      <FormControlLabel
        control={
          <Checkbox
            checked={!!profile?.emailAssetUpdatesNotification}
            onChange={(event) =>
              updateProfile({
                emailAssetUpdatesNotification: event.target.checked,
              })
            }
          />
        }
        label={'Email me with list updates on a weekly basis'}
      />
    </SettingContainer>
  )
}
