import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'

import DialogHeader from '../../../../common/dialog-header'

export interface ShareFeedbackInfoDialogProps {
  open: boolean
  onClose: () => void
}

export default function ShareFeedbackInfoDialog({ open, onClose }: ShareFeedbackInfoDialogProps) {
  return (
    <Dialog
      maxWidth={'sm'}
      onClose={onClose}
      open={open}
      fullWidth>
      <DialogHeader
        onCloseClick={onClose}
        title={''}
        disableDivider
      />
      <DialogContent>
        <Typography
          variant={'h5'}
          gutterBottom>
          Why would I share insights on our fund information on the Gain pro platform?
        </Typography>

        <Typography
          variant={'body2'}
          gutterBottom>
          We do our very best to provide an outside in perspective on your fund. However, you hold a
          superior information position.
        </Typography>

        <Typography
          variant={'body2'}
          gutterBottom>
          With the majority of the ecosystem of potential buyers and their advisors now utilising us
          to develop a perspective, you can benefit from a description that is as accurate as
          possible. Even years before a potential exit, this allows relevant advisors to warm up to
          your assets and accurately track them.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}
