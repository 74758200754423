import { useInvestorStrategyDealsPerTypeByYear } from '@gain/api/app/hooks'
import { SpinnerIcon } from '@gain/components/icons'
import { Investor, InvestorStrategy } from '@gain/rpc/app-model'
import { useOpenLink } from '@gain/utils/router'

import Card, { CardContent, CardHeader } from '../../../../common/card/card'
import CardDealsSummary from '../../../../features/deal/deals-summary-card'
import {
  generateInvestorStrategyPagePath,
  INVESTOR_STRATEGY_ADD_ONS_PAGE_PATH,
  INVESTOR_STRATEGY_ENTRIES_PAGE_PATH,
  INVESTOR_STRATEGY_EXITS_PAGE_PATH,
  useInvestorStrategyPageParams,
} from '../../route-strategy-path'

const INVESTOR_GROUP_CONFIG = [
  { label: 'Entries', key: 'entry' },
  { label: 'Exits', key: 'exit' },
  { label: 'Add-ons', key: 'add-on' },
]

export interface InvestorStrategyDealsCardProps {
  investor: Investor
  strategy: InvestorStrategy
}

const GROUP_TYPE_TO_TAB_MAP = {
  entry: INVESTOR_STRATEGY_ENTRIES_PAGE_PATH,
  exit: INVESTOR_STRATEGY_EXITS_PAGE_PATH,
  'add-on': INVESTOR_STRATEGY_ADD_ONS_PAGE_PATH,
}

export default function InvestorStrategyDealsCard({
  investor,
  strategy,
}: InvestorStrategyDealsCardProps) {
  const params = useInvestorStrategyPageParams()
  const openLink = useOpenLink()

  const swrDeals = useInvestorStrategyDealsPerTypeByYear({
    investorId: investor.id,
    strategyId: strategy.id,
    maxYears: 6,
  })

  return (
    <Card
      sx={{ minHeight: 288 }}
      fullHeight>
      <CardHeader title={'Deals'} />
      <CardContent sx={{ flexGrow: 1 }}>
        {swrDeals.loading && <SpinnerIcon />}
        {!swrDeals.loading && (
          <CardDealsSummary
            data={swrDeals.data}
            groupConfig={INVESTOR_GROUP_CONFIG}
            onClick={(group, event) => {
              openLink(
                generateInvestorStrategyPagePath(params, GROUP_TYPE_TO_TAB_MAP[group.type]),
                event
              )
            }}
          />
        )}
      </CardContent>
    </Card>
  )
}
