import { FinancialResultAmountType } from '@gain/rpc/shared-model'
import { useDialogState } from '@gain/utils/dialog'

import FinancialLegendChip from './financial-legend-chip'
import FinancialRevenueExplainer from './financial-revenue-explainer'

export default function FinancialRevenueAIGeneratedExplainer() {
  const [isOpen, handleOpen, handleClose] = useDialogState()

  return (
    <>
      <FinancialLegendChip
        amountType={FinancialResultAmountType.Ai}
        handleOpen={handleOpen}
        label={'AI estimate'}
      />
      <FinancialRevenueExplainer
        handleClose={handleClose}
        isOpen={isOpen}
      />
    </>
  )
}
