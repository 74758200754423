import Head from '@gain/modules/head'
import { AssetList, Investor, InvestorStrategy } from '@gain/rpc/app-model'
import { generatePath } from 'react-router-dom'

import RepresentativesCard from '../../../features/representatives/representatives-card'
import {
  INVESTOR_STRATEGY_ASSETS_PAGE_PATH,
  useInvestorStrategyPageParams,
} from '../route-strategy-path'

interface RouteRepresentativesProps {
  investor: Investor
  strategy: InvestorStrategy
  assets: AssetList
}

export default function RouteRepresentatives({
  investor,
  strategy,
  assets,
}: RouteRepresentativesProps) {
  const params = useInvestorStrategyPageParams()

  return (
    <>
      <Head>
        <title>
          {investor.name} - {strategy.name} - Representatives
        </title>
      </Head>

      <RepresentativesCard
        assetsIds={assets.items.map(({ id }) => id)}
        investor={investor}
        portfolioPath={generatePath(INVESTOR_STRATEGY_ASSETS_PAGE_PATH, params)}
      />
    </>
  )
}
