import { useListRecentVisits, useRecentlyFilteredBookmarkLists } from '@gain/api/app/hooks'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Divider from '@mui/material/Divider'
import Popper, { PopperProps } from '@mui/material/Popper'
import { styled } from '@mui/material/styles'
import { useRef, useState } from 'react'

import RecentFilterList from '../recent-filter'
import RecentVisitsList from '../recent-visits'

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.appBar,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 8,
  boxShadow: '0px 24px 90px -20px rgba(0, 0, 0, 0.3)',
  padding: theme.spacing(1),
  maxHeight: 570,
  overflowY: 'auto',

  [theme.breakpoints.up('sm')]: {
    width: 464,
  },
  [theme.breakpoints.up('lg')]: {
    width: 664,
  },
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}))

export interface SearchDropdownProps extends PopperProps {
  onClose: () => void
}

export default function SearchDropdown({ anchorEl, onClose, open, ...props }: SearchDropdownProps) {
  const popperRef = useRef(null)
  const [customAssetListOpen, setCustomAssetListOpen] = useState(false)
  const { lists: recentFilterLists } = useRecentlyFilteredBookmarkLists()
  const { data } = useListRecentVisits(undefined, { revalidateOnMount: true })

  if (!data) {
    return null
  }

  const recentVisits = data.items

  if (data.items.length === 0 && recentFilterLists.length === 0) {
    onClose() // Notify parent the popper should remain closed and not update upon trackVisit-requests
    return null // Don't render popover when there is nothing to see
  }

  const handleClose = () => {
    if (!customAssetListOpen) {
      onClose()
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <StyledPopper
        {...props}
        ref={popperRef}
        anchorEl={anchorEl}
        disablePortal={true}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 5], // small gap between search and dropdown
            },
          },
        ]}
        open={open}
        placement={'bottom-start'}>
        {/* Collapse transition does not work with popper:
            https://github.com/mui/material-ui/issues/11337#issuecomment-510578264
        */}

        <RecentFilterList
          onClick={handleClose}
          recentFilterLists={recentFilterLists}
        />

        {recentFilterLists.length > 0 && recentVisits.length > 0 && <StyledDivider />}

        <RecentVisitsList
          items={data.items}
          onClick={handleClose}
          onCloseCustomAssetMenu={() => setCustomAssetListOpen(false)}
          onOpenCustomAssetMenu={() => setCustomAssetListOpen(true)}
        />
      </StyledPopper>
    </ClickAwayListener>
  )
}
