import {
  CalendarIcon,
  GlobeIcon,
  LightbulbIcon,
  LinkedInOutlinedIcon,
  MapPinIcon,
  MoneyBagIcon,
  UsersIcon,
} from '@gain/components/icons'
import { AssetList, Investor } from '@gain/rpc/app-model'
import { formatCountryAndCity } from '@gain/utils/countries'
import { formatFte } from '@gain/utils/financials'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'

import Card, { CardContent, CardHeader } from '../../../../common/card/card'
import EbitdaValuesRange from '../../../../common/ebitda-values-range'
import ListItemIconValue from '../../../../common/list/list-item-icon-value'
import ListItemIconValueLink from '../../../../common/list/list-item-icon-value-link'

export interface InvestorOrganisationCardProps {
  investor: Investor
  assets: AssetList
}

const StyleEbitdaValuesRange = styled(EbitdaValuesRange)({
  maxWidth: 240,
})

export default function InvestorOrganisationCard({
  investor,
  assets,
}: InvestorOrganisationCardProps) {
  const ebitdaValues = useMemo(
    (): number[] => assets.items.map(({ ebitdaEur }) => ebitdaEur).filter(Boolean) as number[],
    [assets.items]
  )

  return (
    <Card fullHeight>
      <CardHeader title={'Organisation'} />
      <CardContent>
        <Stack
          gap={2}
          pb={1}>
          {investor.description && (
            <Typography variant={'body2'}>{investor.description}</Typography>
          )}

          <List
            dense
            disablePadding>
            {investor.operationalHqCountryCode && (
              <ListItemIconValue
                icon={MapPinIcon}
                tooltip={'Operational HQ'}>
                {formatCountryAndCity(
                  investor.operationalHqCountryCode,
                  investor.operationalHqCity
                )}
              </ListItemIconValue>
            )}

            {investor.yearFounded && (
              <ListItemIconValue
                icon={CalendarIcon}
                tooltip={'Year founded'}>
                {investor.yearFounded}
              </ListItemIconValue>
            )}

            {investor.fte && (
              <ListItemIconValue
                icon={UsersIcon}
                tooltip={'Total FTEs'}>
                {formatFte(investor.fte, false)}
              </ListItemIconValue>
            )}

            {investor.url && (
              <ListItemIconValue icon={GlobeIcon}>
                <ListItemIconValueLink url={investor.url} />
              </ListItemIconValue>
            )}

            {investor.linkedInUrl && (
              <ListItemIconValue icon={LinkedInOutlinedIcon}>
                <ListItemIconValueLink url={investor.linkedInUrl} />
              </ListItemIconValue>
            )}

            {ebitdaValues.length > 0 && (
              <ListItemIconValue
                icon={MoneyBagIcon}
                textComponent={'div'}>
                <Stack
                  alignItems={'center'}
                  direction={'row'}
                  gap={4}
                  justifyContent={'space-between'}>
                  Portfolio EBITDA
                  <StyleEbitdaValuesRange ebitdaValues={ebitdaValues} />
                </Stack>
              </ListItemIconValue>
            )}

            {investor.foundedBy && (
              <ListItemIconValue icon={LightbulbIcon}>
                Founded by: {investor.foundedBy}
              </ListItemIconValue>
            )}
          </List>
        </Stack>
      </CardContent>
    </Card>
  )
}
