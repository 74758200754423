import { useInvestorListItem } from '@gain/api/app/hooks'
import { useEffect } from 'react'

import ResourceOfflinePage from '../../common/resource-not-available'
import { useTrackEvent } from '../../features/google-analytics'
import { useInvestorPageParams } from '../utils'

export default function InvestorPageOffline() {
  const params = useInvestorPageParams()
  const investor = useInvestorListItem(params.investorId)
  const trackEvent = useTrackEvent()

  useEffect(() => {
    if (!investor.data) {
      return
    }

    trackEvent('Resource offline', 'Investor detail page', investor.data.name)
  }, [trackEvent, investor.data])

  return (
    <ResourceOfflinePage
      avatarSrc={investor.data?.logoFileUrl}
      message={
        <>
          We found new data points and are processing them. Please come back later to view{' '}
          <strong>{investor.data?.name || params.investorName}</strong>.
        </>
      }
      title={investor.data?.name || params.investorName}
    />
  )
}
