import { useParams } from 'react-router-dom'

import {
  INVESTOR_STRATEGY_PAGE_PATH,
  useInvestorStrategyPageParams,
} from '../investor-strategy/route-strategy-path'
import { Params } from '../utils'

export interface InvestorStrategyAdvisorDealsPagePathParams extends Params {
  advisorId: string
  advisorName: string
}

export const INVESTOR_STRATEGY_PAGE_ADVISOR_DEALS_PATH = `${INVESTOR_STRATEGY_PAGE_PATH}/advisor-deals/:advisorId/:advisorName`

export function useInvestorStrategyAdvisorDealsPageParams() {
  const investorParams = useInvestorStrategyPageParams()
  const advisorParams = useParams<InvestorStrategyAdvisorDealsPagePathParams>()

  return {
    ...investorParams,
    advisorId: parseInt(advisorParams.advisorId, 10),
    advisorName: advisorParams.advisorName,
  }
}
