import { useSetBookmarksFilteredLastViewedAt } from '@gain/api/app/hooks'
import { SlidersIcon } from '@gain/components/icons'
import Tooltip from '@gain/components/tooltip'
import { AssetListItem, BookmarksFilteredList } from '@gain/rpc/app-model'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import { useEffect, useRef } from 'react'
import { generatePath } from 'react-router-dom'

import Skeleton from '../../../common/skeleton'
import { LAST_VIEWED_AT_QUERY_PARAM_KEY } from '../../../routes/assets/use-last-viewed-at-query-param-updates'
import { ASSET_LIST_PATH, BOOKMARKS_FILTERED_PATH } from '../../../routes/utils'
import { AssetFilterField } from '../../asset/asset-filter-bar'
import { FilterModel, filterModelToQueryString } from '../../filter/filter-bar'
import { ObjectListItemLink } from '../../object-list-item'
import RecentFilterActions from './recent-filter-actions'
import RecentFilterListItemGroup from './recent-filter-list-item-group'
import RecentFilterListUpdatesBadge from './recent-filter-list-updates-badge'
import useCalculateNumberOfFilters from './use-calculate-number-of-filters'

const StyledSlidersIcon = styled(SlidersIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: 18,
  margin: theme.spacing(0, 1),
}))

const StyledFilterBox = styled('div')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

const StyledFilterRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  gap: theme.spacing(1),
  minWidth: 0,
}))

const StyledTooltip = styled(Tooltip)({
  maxWidth: 439,
})

const StyledSkeletonRow = styled(StyledFilterRow)({
  flexShrink: 0,
  width: '100%',
})

interface RecentFilterListItemProps {
  list: BookmarksFilteredList | null
  showSkeleton?: boolean
  recentFilterListId?: number
  filterModel?: FilterModel<AssetListItem, AssetFilterField>
  onClick?: () => void
  onLoad?: (list: BookmarksFilteredList) => void
}

export default function RecentFilterListItem({
  list,
  showSkeleton,
  recentFilterListId,
  filterModel,
  onClick,
  onLoad,
}: RecentFilterListItemProps) {
  const rowRef = useRef(null)
  const updateLastViewedAt = useSetBookmarksFilteredLastViewedAt()
  const { isLoading, onChangeValueLabel, extraFilters, showNrOfFilters } =
    useCalculateNumberOfFilters(rowRef, filterModel, list != null && list.updatedAssetCount > 0)

  // Notify parent all async values have loaded
  useEffect(() => {
    if (!isLoading && list) {
      onLoad?.(list)
    }
  }, [isLoading, showNrOfFilters, list, onLoad])

  // handleClick updates the lastViewedAt of recently filtered lists. The
  // updated assets on the companies page are highlighted using
  // `?lastViewedAt=<datetime>` instead.
  //
  // Normal custom query lists update lastViewedAt using a route listener.
  const handleClick = () => {
    if (list?.isRecentlyFiltered) {
      updateLastViewedAt(list.id)
    }
    onClick?.()
  }

  const generateListPath = (
    model?: FilterModel<AssetListItem, AssetFilterField>,
    lastViewedAt?: string | null
  ) => {
    if (!model || !list) {
      return '#'
    }

    // Navigate to the bookmarks page if the filter is saved
    if (!list.isRecentlyFiltered) {
      return generatePath(BOOKMARKS_FILTERED_PATH, { listId: list.id })
    }

    // Navigate to the assets page if the recent filter is not saved
    let path = [ASSET_LIST_PATH, filterModelToQueryString(model)].join('?')
    if (lastViewedAt) {
      path += `&${LAST_VIEWED_AT_QUERY_PARAM_KEY}=${lastViewedAt}`
    }

    return path
  }

  // showSkeleton is used to globally determine a loader state; but
  // when loading additional rows the isLoading takes precedence.
  const loadingItem = isLoading || showSkeleton

  return (
    <ObjectListItemLink
      isSkeleton={loadingItem}
      onClick={handleClick}
      style={{ display: 'block' }}
      to={generateListPath(filterModel, list?.lastViewedAt)}
      data-hj-suppress>
      <StyledSkeletonRow sx={{ display: loadingItem ? 'flex' : 'none' }}>
        <Skeleton enabled>
          <StyledSlidersIcon color={'inherit'} />
        </Skeleton>

        <Skeleton
          boxSx={{ overflow: 'hidden' }}
          width={'40%'}
          enabled
        />
      </StyledSkeletonRow>

      {/* Using visibility we can calculate the correct size */}
      <StyledFilterRow sx={{ visibility: loadingItem ? 'hidden' : 'visible' }}>
        <StyledFilterRow>
          <StyledSlidersIcon color={'inherit'} />

          <StyledFilterRow ref={rowRef}>
            <StyledFilterBox>
              {filterModel?.map((group, groupIndex) => (
                <RecentFilterListItemGroup
                  key={groupIndex}
                  group={group}
                  hidden={groupIndex > showNrOfFilters - 1}
                  index={groupIndex}
                  onChangeValueLabel={onChangeValueLabel}
                />
              ))}
            </StyledFilterBox>

            {extraFilters > 0 && (
              <StyledTooltip
                title={filterModel?.slice(showNrOfFilters).map((group, groupIndex) => (
                  <RecentFilterListItemGroup
                    key={groupIndex}
                    group={group}
                    index={groupIndex}
                  />
                ))}
                variant={'preview'}
                disableInteractive>
                <Chip
                  label={`+${extraFilters} filters`}
                  size={'small'}
                  variant={'outlined'}
                />
              </StyledTooltip>
            )}

            <RecentFilterListUpdatesBadge list={list} />
          </StyledFilterRow>
        </StyledFilterRow>

        {filterModel && list && recentFilterListId && (
          <RecentFilterActions
            filterModel={filterModel}
            list={list}
            recentFilterListId={recentFilterListId}
          />
        )}
      </StyledFilterRow>
    </ObjectListItemLink>
  )
}
