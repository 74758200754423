import { useAccountProjectCode, useUserProfile } from '@gain/api/app/hooks'
import { cloudErrorReporter } from '@gain/modules/cloud-error-reporter'
import { UserContextProvider } from '@gain/modules/user'
import { useIsXs } from '@gain/utils/responsive'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { Suspense, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Redirect, Route, Switch } from 'react-router-dom'

import ConditionalWrapper from '../common/conditional-wrapper'
import Loading from '../common/loading'
import BeamerProvider from '../features/beamer/beamer-provider'
import { useHotjar } from '../features/hotjar'
import Menu, { useMenuContext } from '../features/menu'
import MobileNavigation, { MOBILE_NAVIGATION_HEIGHT } from '../layout/mobile-navigation'
import MainNavigation from '../layout/navigation/main-navigation'
import SiteContainer from '../layout/site-container'
import RouteCredits from '../routes/credits'
import RouteLenders from '../routes/lenders'
import AdvisorRoute from './advisor'
import AdvisorAssetDeals from './advisor-asset-deals'
import AdvisorInvestorDeals from './advisor-investor-deals'
import AdvisorListRoute from './advisors'
import RouteApiDocs from './api-docs'
import RouteArticle from './article'
import RouteAsset from './asset'
import RouteAssets from './assets'
import BookmarksRoute from './bookmarks'
import BookmarksRedirectComponent from './bookmarks/bookmarks-redirect'
import RouteConference from './conference'
import RouteConferences from './conferences'
import DealRoute from './deal'
import DealListPage from './deals/deal-list-page'
import ErrorHandler from './error-handler'
import HelpOptionsRoute from './help-options'
import HomePage from './home'
import IndustriesRoute from './industries'
import RouteIndustry from './industry'
import IndustryPortfolio from './industry-portfolio'
import IndustrySimilarCompanies from './industry-similar-companies'
import InitialDataLoader from './initial-data-loader'
import RouteInvestor from './investor'
import InvestorAdvisorDeals from './investor-advisor-deals'
import RouteInvestorStrategy, { INVESTOR_STRATEGY_PAGE_PATH } from './investor-strategy'
import RouteInvestorStrategyAdvisorDeals, {
  INVESTOR_STRATEGY_PAGE_ADVISOR_DEALS_PATH,
} from './investor-strategy-advisor-deals'
import InvestorListPage from './investors/investor-list-page'
import LegalEntityRoute from './legal-entity'
import RouteLender from './lender/route-lender'
import NotFound from './not-found'
import SearchPage from './search-results/search-page'
import RouteSegment from './segment'
import SegmentCompanies from './segment-companies'
import SegmentSimilarCompanies from './segment-similar-companies'
import SettingsRoutes from './settings'
import { UserSessions } from './user-sessions/user-sessions'
import {
  ADVISOR_LIST_PATH,
  ADVISOR_PAGE_CLIENTS_ASSETS_PATH,
  ADVISOR_PAGE_CLIENTS_INVESTORS_PATH,
  ADVISOR_PAGE_PATH,
  API_DOCS_PATH,
  ARTICLE_PAGE_PATH,
  ASSET_LIST_PATH,
  ASSET_PAGE_PATH,
  BOOKMARKS_PATH,
  CONFERENCE_LIST_PATH,
  CONFERENCE_PAGE_PATH,
  CREDITS_LIST_PATH,
  DEAL_LIST_PATH,
  DEAL_PAGE_PATH,
  HELP_OPTIONS_PATH,
  HOME_PATH,
  INDUSTRY_LIST_PATH,
  INDUSTRY_PAGE_ASSETS_PATH,
  INDUSTRY_PAGE_PATH,
  INDUSTRY_PAGE_SIMILAR_ASSETS_PATH,
  INVESTOR_LIST_PATH,
  INVESTOR_PAGE_ADVISOR_DEALS_PATH,
  INVESTOR_PAGE_PATH,
  LEGAL_ENTITY_PAGE_PATH,
  LENDER_PAGE_PATH,
  LENDERS_LIST_PATH,
  OLD_MY_LISTS_PATH,
  ROUTE_SEGMENT_ASSETS_PATH,
  ROUTE_SEGMENT_PATH,
  ROUTE_SEGMENT_SIMILAR_ASSETS_PATH,
  SEARCH_PAGE_PATH,
  SETTINGS_PATH,
  USER_SESSIONS_PATH,
} from './utils'

const StyledShellContent = styled('div', {
  shouldForwardProp: (propName) => propName !== 'shiftContent',
})<{ shiftContent: boolean }>(({ theme, shiftContent }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  position: 'relative',
  minWidth: 0,
  minHeight: 0,
  ...(shiftContent && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  }),
}))

const StyledMobileContainer = styled('div')({
  minHeight: `calc(100vh - ${MOBILE_NAVIGATION_HEIGHT}px)`,
  display: 'flex',
  flexDirection: 'column',
})

export function PrivateRoutes() {
  const [isOpen, , isMenuEnabled] = useMenuContext()
  const userProfile = useUserProfile()
  const projectCode = useAccountProjectCode()
  const initHotjar = useHotjar()
  const isXs = useIsXs()

  useEffect(() => {
    if (userProfile.loading) {
      return
    }

    if (userProfile?.data?.id) {
      cloudErrorReporter.setUser(`${userProfile.data.id}`)
    }

    initHotjar(userProfile.data)
  }, [initHotjar, userProfile.data, userProfile.loading])

  return (
    <UserContextProvider
      onProjectCodeChange={projectCode.update}
      userProfile={userProfile.data}>
      <InitialDataLoader>
        <BeamerProvider>
          <Suspense fallback={<Loading />}>
            <ConditionalWrapper
              condition={!isXs}
              wrapper={(children) => (
                <SiteContainer showMenu={isOpen}>
                  <MainNavigation />
                  <Box
                    display={'flex'}
                    flex={1}
                    minHeight={0}
                    minWidth={0}>
                    {isMenuEnabled && <Menu />}
                    <StyledShellContent shiftContent={isOpen}>{children}</StyledShellContent>
                  </Box>

                  <MobileNavigation />
                </SiteContainer>
              )}
              wrapperWhenFalse={(children) => (
                <>
                  <StyledMobileContainer>{children}</StyledMobileContainer>
                  <MobileNavigation />
                </>
              )}>
              <ErrorBoundary FallbackComponent={ErrorHandler}>
                <Suspense fallback={<Loading />}>
                  <Switch>
                    <Route
                      component={HomePage}
                      path={[HOME_PATH]}
                    />
                    <Route
                      component={RouteApiDocs}
                      path={API_DOCS_PATH}
                    />
                    <Route
                      component={RouteArticle}
                      path={ARTICLE_PAGE_PATH}
                      exact
                    />
                    <Route
                      component={InvestorListPage}
                      path={INVESTOR_LIST_PATH}
                    />
                    <Route
                      component={DealListPage}
                      path={DEAL_LIST_PATH}
                      exact
                    />
                    <Route
                      component={BookmarksRedirectComponent}
                      path={OLD_MY_LISTS_PATH}
                    />
                    <Route
                      component={SettingsRoutes}
                      path={SETTINGS_PATH}
                    />
                    <Route
                      component={UserSessions}
                      path={USER_SESSIONS_PATH}
                      exact
                    />
                    <Route
                      component={IndustriesRoute}
                      path={INDUSTRY_LIST_PATH}
                    />
                    <Route
                      component={SegmentCompanies}
                      path={ROUTE_SEGMENT_ASSETS_PATH}
                      exact
                    />
                    <Route
                      component={SegmentSimilarCompanies}
                      path={ROUTE_SEGMENT_SIMILAR_ASSETS_PATH}
                      exact
                    />
                    <Route
                      component={RouteSegment}
                      path={ROUTE_SEGMENT_PATH}
                    />
                    <Route
                      component={RouteAssets}
                      path={ASSET_LIST_PATH}
                    />
                    <Route
                      component={RouteAsset}
                      path={ASSET_PAGE_PATH}
                    />

                    <Route
                      component={DealRoute}
                      path={DEAL_PAGE_PATH}
                      exact
                    />

                    <Route
                      component={LegalEntityRoute}
                      path={LEGAL_ENTITY_PAGE_PATH}
                      exact
                    />

                    <Route
                      component={RouteInvestorStrategyAdvisorDeals}
                      path={INVESTOR_STRATEGY_PAGE_ADVISOR_DEALS_PATH}
                      exact
                    />
                    <Route
                      component={RouteInvestorStrategy}
                      path={INVESTOR_STRATEGY_PAGE_PATH}
                    />
                    <Route
                      component={InvestorAdvisorDeals}
                      path={INVESTOR_PAGE_ADVISOR_DEALS_PATH}
                      exact
                    />
                    <Route
                      component={RouteInvestor}
                      path={INVESTOR_PAGE_PATH}
                    />
                    <Route
                      component={RouteLender}
                      path={LENDER_PAGE_PATH}
                    />
                    <Route
                      component={RouteLenders}
                      path={LENDERS_LIST_PATH}
                    />
                    <Route
                      component={RouteCredits}
                      path={CREDITS_LIST_PATH}
                    />
                    <Route
                      component={IndustryPortfolio}
                      path={INDUSTRY_PAGE_ASSETS_PATH}
                      exact
                    />
                    <Route
                      component={IndustrySimilarCompanies}
                      path={INDUSTRY_PAGE_SIMILAR_ASSETS_PATH}
                      exact
                    />
                    <Route
                      component={RouteIndustry}
                      path={INDUSTRY_PAGE_PATH}
                    />
                    <Route
                      component={BookmarksRoute}
                      path={BOOKMARKS_PATH}
                    />
                    <Route
                      component={SearchPage}
                      path={SEARCH_PAGE_PATH}
                      exact
                    />
                    <Route
                      component={HelpOptionsRoute}
                      path={HELP_OPTIONS_PATH}
                      exact
                    />

                    <Route
                      component={AdvisorInvestorDeals}
                      path={ADVISOR_PAGE_CLIENTS_INVESTORS_PATH}
                      exact
                    />
                    <Route
                      component={AdvisorAssetDeals}
                      path={ADVISOR_PAGE_CLIENTS_ASSETS_PATH}
                      exact
                    />
                    <Route
                      component={AdvisorRoute}
                      path={ADVISOR_PAGE_PATH}
                    />
                    <Route
                      component={AdvisorListRoute}
                      path={ADVISOR_LIST_PATH}
                    />

                    <Route
                      component={RouteConference}
                      path={CONFERENCE_PAGE_PATH}
                    />

                    <Route
                      component={RouteConferences}
                      path={CONFERENCE_LIST_PATH}
                    />

                    <Route
                      path={'/'}
                      exact>
                      <Redirect to={HOME_PATH} />
                    </Route>

                    <Route component={NotFound} />
                  </Switch>
                </Suspense>
              </ErrorBoundary>
            </ConditionalWrapper>
          </Suspense>
        </BeamerProvider>
      </InitialDataLoader>
    </UserContextProvider>
  )
}
