import { useUserCurrency } from '@gain/api/app/hooks'
import {
  CalendarIcon,
  CeoIcon,
  MoneyBagIcon,
  ShuffleIcon,
  SlidersIcon,
  StarOutlinedIcon,
  TrendingUpIcon,
  UsersIcon,
} from '@gain/components/icons'
import { AssetListItem } from '@gain/rpc/app-model'
import { useConvertCurrencyCallback, useFormatCurrencyCallback } from '@gain/utils/currency'
import { parseYearMonth } from '@gain/utils/date'
import { formatRevenueFte } from '@gain/utils/financials'
import { ratingInvestmentCriteriaMap, RatingKeys } from '@gain/utils/investment-criteria'
import { formatMultiple, formatNumber, formatPercentage, formatThousands } from '@gain/utils/number'
import { NumberOrNullKeys } from '@gain/utils/typescript'
import Chip from '@mui/material/Chip'
import { differenceInMonths } from 'date-fns/differenceInMonths'
import { OptionAxisType } from 'echarts/types/src/coord/axisCommonTypes'
import { CartesianAxisOption } from 'echarts/types/src/coord/cartesian/AxisModel'
import { ReactNode, useCallback, useMemo } from 'react'

import { dropdownMenuGroup, DropdownMenuOptions } from '../../../../common/dropdown-menu'
import {
  DropdownMenuOption,
  isDropdownMenuGroup,
} from '../../../../common/dropdown-menu/dropdown-menu-model'
import { calculateFteRangeAvg } from '../../../../common/fte'
import { axisBucketConfig, financialPctBucketOptions, growthPctBucketOptions } from './axis-bucket'
import { axisLinearConfig } from './axis-linear'
import { axisLogConfig } from './axis-log'
import { axisRatingConfig } from './axis-rating'
import { determineAxisLabelDecimals } from './axis-utils'

/**
 * The ID of an axis config should match the key of a numeric field of an
 * asset list item, or must be a computed value in the frontend.
 */
export type AxisConfigId = NumberOrNullKeys<AssetListItem> | 'companyAge' | 'yearsSinceLastDeal'

/**
 * Denotes an X or Y axis.
 */
export enum Axis {
  X = 'x',
  Y = 'y',
}

export interface AxisConfig extends Omit<DropdownMenuOption, 'id'> {
  id: AxisConfigId
  getValue: (asset: AssetListItem) => number | null
  formatTooltip: (value: AssetListItem) => string
  formatTooltipLabel?: (value: AssetListItem) => string | ReactNode
  formatLabel?: (value: number) => string
  generateLabels: (axis: Axis) => CartesianAxisOption
  scaleType: OptionAxisType
  scaleMin: number
  scaleMax: number
  splitNumber?: number

  // Calculates the axis value for an asset.
  calculateAxisValue: (
    value: AssetListItem,
    axis: Axis,
    otherAxisConfig: AxisConfig
  ) => number | null

  // Spacing available for the axis label; only use this if you have labels that
  // are too long to fit in the default space.
  calculateLabelSpace?: (axis: Axis) => number
}

/**
 * The list of options in the axis select dropdown menu.
 */
export function useAxisConfig(assets: AssetListItem[]): DropdownMenuOptions<AxisConfig> {
  const userCurrency = useUserCurrency()
  const formatCurrency = useFormatCurrencyCallback()
  const convertCurrency = useConvertCurrencyCallback()
  const convertToUserCurrency = useCallback(
    (value: number | null) => {
      return convertCurrency(value, 'EUR', userCurrency.name)
    },
    [convertCurrency, userCurrency.name]
  )

  return useMemo(() => {
    const formatMillionsLabel = (value: number) =>
      formatCurrency(value, {
        round: determineAxisLabelDecimals(value),
        disablePrefix: true,
        dataCurrency: userCurrency.name,
        format: 'millions-or-billions',
      })

    const formatThousandsLabel = (value: number) => {
      return formatCurrency(value, {
        round: determineAxisLabelDecimals(value),
        disablePrefix: true,
        dataCurrency: userCurrency.name,
        format: 'thousands-or-millions',
      })
    }

    const calculateCompanyAge = (asset: AssetListItem) => {
      if (asset.yearFounded === null) {
        return null
      }

      const diff = new Date().getFullYear() - asset.yearFounded

      // Increment age if current month is past June
      return new Date().getMonth() > 5 ? diff + 1 : diff
    }

    const calculateYearsSinceLastDeal = (asset: AssetListItem) => {
      if (asset.lastDealYear === null && asset.lastDealMonth === null) {
        return null
      }

      const lastDealDate = parseYearMonth(asset.lastDealYear, asset.lastDealMonth)
      return differenceInMonths(new Date(), lastDealDate) / 12
    }

    return [
      axisLogConfig({
        id: 'fte',
        label: 'FTEs',
        icon: UsersIcon,
        assets,
        getValue: (asset) => {
          if (asset.fte !== null) {
            return asset.fte
          }

          // If fte is not defined but the fte range is, we use the midpoint of the range.
          if (asset.fteRange !== null) {
            return calculateFteRangeAvg(asset.fteRange)
          }

          return null
        },
        formatLabel: (value) => formatThousands(value),
        formatTooltip: (asset) => {
          if (typeof asset.fte === 'number') {
            return formatThousands(asset.fte)
          }

          if (typeof asset.fteRange === 'string') {
            return asset.fteRange
          }

          return '-'
        },
      }),
      dropdownMenuGroup('financials', 'Financials', {
        icon: MoneyBagIcon,
        children: [
          axisLogConfig({
            id: 'revenueWithAiGeneratedEur',
            label: 'Revenue',
            assets,
            formatLabel: formatMillionsLabel,
            getValue: (asset) => convertToUserCurrency(asset.revenueWithAiGeneratedEur),
            formatTooltip: (asset) => formatCurrency(asset.revenueWithAiGeneratedEur),
            formatTooltipLabel: (asset) => (
              <>
                Revenue
                {asset.revenueIsAiGenerated && (
                  <>
                    &nbsp;
                    <Chip
                      color={'warning'}
                      label={'AI'}
                      size={'small'}
                    />
                  </>
                )}
              </>
            ),
          }),
          dropdownMenuGroup('grossMargin', 'Gross margin', {
            children: [
              axisLogConfig({
                id: 'grossMarginEur',
                label: 'Gross margin',
                assets,
                formatLabel: formatMillionsLabel,
                getValue: (asset) => convertToUserCurrency(asset.grossMarginEur),
                formatTooltip: (asset) => formatCurrency(asset.grossMarginEur),
              }),
              axisBucketConfig({
                id: 'grossMarginPctRevenue',
                label: 'Gross margin %',
                buckets: financialPctBucketOptions,
                assets,
                getValue: (asset) => asset.grossMarginPctRevenue,
                formatTooltip: (asset) => formatPercentage(asset.grossMarginPctRevenue),
              }),
            ],
          }),
          dropdownMenuGroup('ebitda', 'EBITDA', {
            children: [
              axisLogConfig({
                id: 'ebitdaEur',
                label: 'EBITDA',
                assets,
                formatLabel: formatMillionsLabel,
                getValue: (asset) => convertToUserCurrency(asset.ebitdaEur),
                formatTooltip: (asset) => formatCurrency(asset.ebitdaEur),
              }),
              axisBucketConfig({
                id: 'ebitdaPctRevenue',
                label: 'EBITDA %',
                buckets: financialPctBucketOptions,
                assets,
                getValue: (asset) => asset.ebitdaPctRevenue,
                formatTooltip: (asset) => formatPercentage(asset.ebitdaPctRevenue),
              }),
            ],
          }),
          dropdownMenuGroup('ebit', 'EBIT', {
            children: [
              axisLogConfig({
                id: 'ebitEur',
                label: 'EBIT',
                assets,
                formatLabel: formatMillionsLabel,
                getValue: (asset) => convertToUserCurrency(asset.ebitEur),
                formatTooltip: (asset) => formatCurrency(asset.ebitEur),
              }),
              axisBucketConfig({
                id: 'ebitPctRevenue',
                label: 'EBIT %',
                buckets: financialPctBucketOptions,
                assets,
                getValue: (asset) => asset.ebitPctRevenue,
                formatTooltip: (asset) => formatPercentage(asset.ebitPctRevenue),
              }),
            ],
          }),
          axisLogConfig({
            id: 'capexEur',
            label: 'CAPEX',
            assets,
            formatLabel: formatMillionsLabel,
            getValue: (asset) => convertToUserCurrency(asset.capexEur),
            formatTooltip: (asset) => formatCurrency(asset.capexEur),
          }),
          axisLogConfig({
            id: 'ebitdaMinusCapexEur',
            label: 'EBITDA - CAPEX',
            assets,
            formatLabel: formatMillionsLabel,
            getValue: (asset) => convertToUserCurrency(asset.ebitdaMinusCapexEur),
            formatTooltip: (asset) => formatCurrency(asset.ebitdaMinusCapexEur),
          }),
          axisLogConfig({
            id: 'totalAssetsEur',
            label: 'Total assets',
            assets,
            formatLabel: formatMillionsLabel,
            getValue: (asset) => convertToUserCurrency(asset.totalAssetsEur),
            formatTooltip: (asset) => formatCurrency(asset.totalAssetsEur),
          }),
          dropdownMenuGroup('debt', 'Debt', {
            children: [
              axisLogConfig({
                id: 'netDebtEur',
                label: 'Net debt',
                assets,
                formatLabel: formatMillionsLabel,
                getValue: (asset) => convertToUserCurrency(asset.netDebtEur),
                formatTooltip: (asset) => formatCurrency(asset.netDebtEur),
              }),
              axisLogConfig({
                id: 'debtEur',
                label: 'Interest-bearing debt',
                assets,
                formatLabel: formatMillionsLabel,
                getValue: (asset) => convertToUserCurrency(asset.debtEur),
                formatTooltip: (asset) => formatCurrency(asset.debtEur),
              }),
              axisLogConfig({
                id: 'cashEur',
                label: 'Cash & cash equivalents',
                assets,
                formatLabel: formatMillionsLabel,
                getValue: (asset) => convertToUserCurrency(asset.cashEur),
                formatTooltip: (asset) => formatCurrency(asset.cashEur),
              }),
            ],
          }),
          dropdownMenuGroup('workingCapital', 'Working capital', {
            children: [
              axisLogConfig({
                id: 'capitalEur',
                label: 'Trade working capital',
                assets,
                formatLabel: formatMillionsLabel,
                getValue: (asset) => convertToUserCurrency(asset.capitalEur),
                formatTooltip: (asset) => formatCurrency(asset.capitalEur),
              }),
              axisLogConfig({
                id: 'inventoryEur',
                label: 'Inventory',
                assets,
                formatLabel: formatMillionsLabel,
                getValue: (asset) => convertToUserCurrency(asset.inventoryEur),
                formatTooltip: (asset) => formatCurrency(asset.inventoryEur),
              }),
              axisLogConfig({
                id: 'receivablesEur',
                label: 'Receivables',
                assets,
                formatLabel: formatMillionsLabel,
                getValue: (asset) => convertToUserCurrency(asset.receivablesEur),
                formatTooltip: (asset) => formatCurrency(asset.receivablesEur),
              }),
              axisLogConfig({
                id: 'payablesEur',
                label: 'Payables',
                assets,
                formatLabel: formatMillionsLabel,
                getValue: (asset) => convertToUserCurrency(asset.payablesEur),
                formatTooltip: (asset) => formatCurrency(asset.payablesEur),
              }),
            ],
          }),
        ],
      }),
      dropdownMenuGroup('ratios', 'Ratios', {
        icon: SlidersIcon,
        children: [
          axisLogConfig({
            id: 'revenueFteRatioEur',
            label: 'Revenue / FTE',
            assets,
            formatLabel: formatThousandsLabel,
            getValue: (asset) => convertToUserCurrency(asset.revenueFteRatioEur),
            formatTooltip: (asset) => formatRevenueFte(asset.revenueFteRatioEur),
          }),
          axisLogConfig({
            id: 'netDebtEbitdaRatio',
            label: 'Net debt / EBITDA',
            logBase: 2,
            assets,
            getValue: (asset) => asset.netDebtEbitdaRatio,
            formatLabel: (value) => formatMultiple(value, { round: 0 }),
            formatTooltip: (asset) => formatMultiple(asset.netDebtEbitdaRatio, { round: 1 }),
          }),
        ],
      }),
      dropdownMenuGroup('growth', 'Growth', {
        icon: TrendingUpIcon,
        children: [
          dropdownMenuGroup('fte', 'FTE growth', {
            children: [
              axisBucketConfig({
                id: 'fteGrowthPctThreeMonths',
                label: '3M FTE growth %',
                buckets: growthPctBucketOptions,
                assets,
                getValue: (asset) => asset.fteGrowthPctThreeMonths,
                formatTooltip: (asset) =>
                  formatPercentage(asset.fteGrowthPctThreeMonths, { round: 1 }),
              }),
              axisBucketConfig({
                id: 'fteGrowthPctSixMonths',
                label: '6M FTE growth %',
                buckets: growthPctBucketOptions,
                assets,
                getValue: (asset) => asset.fteGrowthPctSixMonths,
                formatTooltip: (asset) =>
                  formatPercentage(asset.fteGrowthPctSixMonths, { round: 1 }),
              }),
              axisBucketConfig({
                id: 'fteGrowthPctOneYear',
                label: '1Y FTE growth %',
                buckets: growthPctBucketOptions,
                assets,
                getValue: (asset) => asset.fteGrowthPctOneYear,
                formatTooltip: (asset) => formatPercentage(asset.fteGrowthPctOneYear, { round: 1 }),
              }),
              axisBucketConfig({
                id: 'fteCagrPctTwoYears',
                label: '2Y FTE CAGR %',
                buckets: growthPctBucketOptions,
                assets,
                getValue: (asset) => asset.fteCagrPctTwoYears,
                formatTooltip: (asset) => formatPercentage(asset.fteCagrPctTwoYears, { round: 1 }),
              }),
              axisBucketConfig({
                id: 'fteCagrPctThreeYears',
                label: '3Y FTE CAGR %',
                buckets: growthPctBucketOptions,
                assets,
                getValue: (asset) => asset.fteCagrPctThreeYears,
                formatTooltip: (asset) =>
                  formatPercentage(asset.fteCagrPctThreeYears, { round: 1 }),
              }),
            ],
          }),
          dropdownMenuGroup('revenueGrowth', 'Revenue growth', {
            children: [
              axisBucketConfig({
                id: 'revenueGrowthPctOneYear',
                label: '1Y revenue growth %',
                buckets: growthPctBucketOptions,
                assets,
                getValue: (asset) => asset.revenueGrowthPctOneYear,
                formatTooltip: (asset) =>
                  formatPercentage(asset.revenueGrowthPctOneYear, { round: 1 }),
              }),
              axisBucketConfig({
                id: 'revenueCagrPctTwoYears',
                label: '2Y revenue CAGR %',
                buckets: growthPctBucketOptions,
                assets,
                getValue: (asset) => asset.revenueCagrPctTwoYears,
                formatTooltip: (asset) =>
                  formatPercentage(asset.revenueCagrPctTwoYears, { round: 1 }),
              }),
              axisBucketConfig({
                id: 'revenueCagrPctThreeYears',
                label: '3Y revenue CAGR %',
                buckets: growthPctBucketOptions,
                assets,
                getValue: (asset) => asset.revenueCagrPctThreeYears,
                formatTooltip: (asset) =>
                  formatPercentage(asset.revenueCagrPctThreeYears, { round: 1 }),
              }),
            ],
          }),
          dropdownMenuGroup('grossMarginGrowth', 'Gross margin growth', {
            children: [
              axisBucketConfig({
                id: 'grossMarginGrowthPctOneYear',
                label: '1Y Gross margin growth %',
                buckets: growthPctBucketOptions,
                assets,
                getValue: (asset) => asset.grossMarginGrowthPctOneYear,
                formatTooltip: (asset) =>
                  formatPercentage(asset.grossMarginGrowthPctOneYear, { round: 1 }),
              }),
              axisBucketConfig({
                id: 'grossMarginCagrPctTwoYears',
                label: '2Y Gross margin CAGR %',
                buckets: growthPctBucketOptions,
                assets,
                getValue: (asset) => asset.grossMarginCagrPctTwoYears,
                formatTooltip: (asset) =>
                  formatPercentage(asset.grossMarginCagrPctTwoYears, { round: 1 }),
              }),
              axisBucketConfig({
                id: 'grossMarginCagrPctThreeYears',
                label: '3Y Gross margin CAGR %',
                buckets: growthPctBucketOptions,
                assets,
                getValue: (asset) => asset.grossMarginCagrPctThreeYears,
                formatTooltip: (asset) =>
                  formatPercentage(asset.grossMarginCagrPctThreeYears, { round: 1 }),
              }),
            ],
          }),
          dropdownMenuGroup('ebitdaGrowth', 'EBITDA growth', {
            children: [
              axisBucketConfig({
                id: 'ebitdaGrowthPctOneYear',
                label: '1Y EBITDA growth %',
                buckets: growthPctBucketOptions,
                assets,
                getValue: (asset) => asset.ebitdaGrowthPctOneYear,
                formatTooltip: (asset) =>
                  formatPercentage(asset.ebitdaGrowthPctOneYear, { round: 1 }),
              }),
              axisBucketConfig({
                id: 'ebitdaCagrPctTwoYears',
                label: '2Y EBITDA CAGR %',
                buckets: growthPctBucketOptions,
                assets,
                getValue: (asset) => asset.ebitdaCagrPctTwoYears,
                formatTooltip: (asset) =>
                  formatPercentage(asset.ebitdaCagrPctTwoYears, { round: 1 }),
              }),
              axisBucketConfig({
                id: 'ebitdaCagrPctThreeYears',
                label: '3Y EBITDA CAGR %',
                buckets: growthPctBucketOptions,
                assets,
                getValue: (asset) => asset.ebitdaCagrPctThreeYears,
                formatTooltip: (asset) =>
                  formatPercentage(asset.ebitdaCagrPctThreeYears, { round: 1 }),
              }),
            ],
          }),
          dropdownMenuGroup('ebitGrowth', 'EBIT growth', {
            children: [
              axisBucketConfig({
                id: 'ebitGrowthPctOneYear',
                label: '1Y EBIT growth %',
                buckets: growthPctBucketOptions,
                assets,
                getValue: (asset) => asset.ebitGrowthPctOneYear,
                formatTooltip: (asset) =>
                  formatPercentage(asset.ebitGrowthPctOneYear, { round: 1 }),
              }),
              axisBucketConfig({
                id: 'ebitCagrPctTwoYears',
                label: '2Y EBIT CAGR %',
                buckets: growthPctBucketOptions,
                assets,
                getValue: (asset) => asset.ebitCagrPctTwoYears,
                formatTooltip: (asset) => formatPercentage(asset.ebitCagrPctTwoYears, { round: 1 }),
              }),
              axisBucketConfig({
                id: 'ebitCagrPctThreeYears',
                label: '3Y EBIT CAGR %',
                buckets: growthPctBucketOptions,
                assets,
                getValue: (asset) => asset.ebitCagrPctThreeYears,
                formatTooltip: (asset) =>
                  formatPercentage(asset.ebitCagrPctThreeYears, { round: 1 }),
              }),
            ],
          }),
        ],
      }),
      dropdownMenuGroup('deals', 'Deals', {
        icon: ShuffleIcon,
        children: [
          dropdownMenuGroup<AxisConfig>('pastDeal', 'Past deals and funding', {
            children: [
              axisLinearConfig({
                id: 'yearsSinceLastDeal',
                label: 'Years since last deal',
                splitNumber: 10,
                scaleMin: 0,
                outlierPct: 0.05,
                assets,
                getValue: (asset) => calculateYearsSinceLastDeal(asset),
                formatTooltip: (asset) =>
                  formatNumber(calculateYearsSinceLastDeal(asset), { round: 1 }),
              }),
              axisLogConfig({
                id: 'totalDealFundingRaisedEur',
                label: 'Total funding',
                assets,
                formatLabel: formatMillionsLabel,
                getValue: (asset) => convertToUserCurrency(asset.totalDealFundingRaisedEur),
                formatTooltip: (asset) => formatCurrency(asset.totalDealFundingRaisedEur),
              }),
              axisLogConfig({
                id: 'latestDealRoundSizeEur',
                label: 'Latest round size',
                assets,
                formatLabel: formatMillionsLabel,
                getValue: (asset) => convertToUserCurrency(asset.latestDealRoundSizeEur),
                formatTooltip: (asset) => formatCurrency(asset.latestDealRoundSizeEur),
              }),
              axisLogConfig({
                id: 'latestDealPreMoneyValuationEur',
                label: 'Latest pre-money valuation',
                assets,
                formatLabel: formatMillionsLabel,
                getValue: (asset) => convertToUserCurrency(asset.latestDealPreMoneyValuationEur),
                formatTooltip: (asset) => formatCurrency(asset.latestDealPreMoneyValuationEur),
              }),
              axisLogConfig({
                id: 'latestDealPostMoneyValuationEur',
                label: 'Latest post-money valuation',
                assets,
                formatLabel: formatMillionsLabel,
                getValue: (asset) => convertToUserCurrency(asset.latestDealPostMoneyValuationEur),
                formatTooltip: (asset) => formatCurrency(asset.latestDealPostMoneyValuationEur),
              }),
              axisLinearConfig({
                id: 'addOnDealCountL3Y',
                label: 'Number of add-ons (L3Y)',
                splitNumber: 10,
                assets,
                getValue: (asset) => asset.addOnDealCountL3Y,
                formatTooltip: (asset) => formatNumber(asset.addOnDealCountL3Y),
              }),
              axisLinearConfig({
                id: 'addOnDealCountL5Y',
                label: 'Number of add-ons (L5Y)',
                splitNumber: 10,
                assets,
                getValue: (asset) => asset.addOnDealCountL5Y,
                formatTooltip: (asset) => formatNumber(asset.addOnDealCountL5Y),
              }),
            ],
          }),
          dropdownMenuGroup('nextDeal', 'Next deal', {
            children: [
              axisLogConfig({
                id: 'predictedExitEbitdaEur',
                label: 'Predicted EBITDA',
                assets,
                formatLabel: formatMillionsLabel,
                getValue: (asset) => convertToUserCurrency(asset.predictedExitEbitdaEur),
                formatTooltip: (asset) => formatCurrency(asset.predictedExitEbitdaEur),
              }),
              axisLinearConfig({
                id: 'predictedExitMultiple',
                label: 'Predicted multiple',
                scaleMin: 0,
                splitNumber: 10,
                assets,
                getValue: (asset) => asset.predictedExitMultiple,
                formatLabel: (value) => formatMultiple(value, { round: 0 }),
                formatTooltip: (asset) => formatMultiple(asset.predictedExitMultiple, { round: 0 }),
              }),
              axisLogConfig({
                id: 'predictedExitEvEur',
                label: 'Predicted EV',
                assets,
                formatLabel: formatMillionsLabel,
                getValue: (asset) => convertToUserCurrency(asset.predictedExitEvEur),
                formatTooltip: (asset) => formatCurrency(asset.predictedExitEvEur),
              }),
              axisLinearConfig({
                id: 'predictedExitYear',
                label: 'Predicted exit year',
                scaleMin: new Date().getFullYear(),
                scaleMax: new Date().getFullYear() + 6,
                splitNumber: 7,
                assets,
                getValue: (asset) => asset.predictedExitYear,
                formatTooltip: (asset) => `${asset.predictedExitYear}`,
              }),
            ],
          }),
        ],
      }),
      dropdownMenuGroup('assessment', 'Assessment', {
        icon: StarOutlinedIcon,
        children: (Object.keys(ratingInvestmentCriteriaMap) as RatingKeys<AssetListItem>[])
          .filter((key) => key !== 'ratingOverall')
          .map((id) => axisRatingConfig({ id, assets })),
      }),
      dropdownMenuGroup('ceoExperience', 'CEO experience', {
        icon: CeoIcon,
        children: [
          axisBucketConfig({
            id: 'ceoAge',
            label: 'CEO age',
            buckets: [[30, 35, 40, 45, 50, 55, 60, 65, 70, 80]],
            assets,
            getValue: (asset) => asset.ceoAge,
            formatTooltip: (asset) => formatNumber(asset.ceoAge),
          }),
          axisLinearConfig({
            id: 'ceoTenure',
            label: 'CEO tenure',
            scaleMin: 0,
            scaleMax: 35,
            splitNumber: 7,
            assets,
            getValue: (asset) => asset.ceoTenure,
            formatTooltip: (asset) => formatNumber(asset.ceoTenure),
          }),
        ],
      }),
      axisLinearConfig({
        id: 'companyAge',
        label: 'Company age',
        icon: CalendarIcon,
        splitNumber: 10,
        scaleMin: 0,
        outlierPct: 0.05,
        assets,
        getValue: calculateCompanyAge,
        formatTooltip: (asset) => formatNumber(calculateCompanyAge(asset)),
      }),
    ]
  }, [assets, convertToUserCurrency, formatCurrency, userCurrency.name])
}

/**
 * Find an axis config by its ID.
 */
export function findAxisConfigById(
  id: AxisConfigId,
  axisMenu: DropdownMenuOptions<AxisConfig>
): AxisConfig | null {
  const findRecursively = (options: DropdownMenuOptions<AxisConfig>) => {
    for (const option of options) {
      // Check if any children match the ID
      if (isDropdownMenuGroup(option)) {
        const result = findRecursively(option.children)
        if (result) {
          return result
        }
      } else {
        // Check if this is the option we're looking for
        if (option.id === id) {
          return option
        }
      }
    }

    return null // No match
  }

  return findRecursively(axisMenu)
}
