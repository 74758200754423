import { ChevronRightIcon } from '@gain/components/icons'
import Tooltip from '@gain/components/tooltip'
import Typography from '@gain/components/typography'
import { formatPercentage } from '@gain/utils/number'
import Collapse from '@mui/material/Collapse'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled, useTheme } from '@mui/material/styles'
import { typographyClasses } from '@mui/material/Typography'
import clsx from 'clsx'
import { Fragment, MouseEvent, useCallback, useMemo, useState } from 'react'

import { getTextWidth } from '../../features/chart'
import MenuExpandIcon from '../menu-expand-icon'
import Bar from './bar'

export interface LeafRow {
  label: string
  type: string
  count: number
  percentage: number
  formattedPercentage: string
  color?: string
}

export interface ValueDistributionRow {
  label: string
  type: string
  color: string
  count: number
  percentage: number
  formattedPercentage: string
  values?: LeafRow[]
  href?: string
}

const tableValueDistributionClasses = generateUtilityClasses('TableValueDistribution', [
  'cell',
  'row',
  'tbodyValues',
  'expanded',
  'textSecondary',
  'clickable',
])

const StyledTable = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  tableLayout: 'auto',
  cellSpacing: 0,
})

const StyledRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  padding: theme.spacing(0, 3),
  gap: theme.spacing(1),
  [`& .${tableValueDistributionClasses.cell}:first-of-type`]: {
    flex: 1,
  },
  [`&.${tableValueDistributionClasses.clickable}:hover`]: {
    backgroundColor: theme.palette.grey['50'],
  },
}))

const StyledTHead = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(1),
}))

const StyledTBody = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [`&.${tableValueDistributionClasses.tbodyValues}`]: {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.grey['50'],
  },
}))

const StyledTh = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 40,
  textAlign: 'left',
  ...theme.typography.overline,
  color: theme.palette.text.secondary,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}))

const StyledTd = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 36,
  textAlign: 'left',
  minWidth: 0,
  ...theme.typography.body2,
  color: theme.palette.text.primary,
  [`&.${tableValueDistributionClasses.textSecondary}`]: {
    color: theme.palette.text.secondary,
  },

  '& > div, > a': {
    display: 'flex',
    minWidth: 0,
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.text.primary,

    [`& .${typographyClasses.root}`]: {
      display: 'block',

      '& svg': {
        verticalAlign: 'middle',
      },
    },
  },
}))

export interface TableValueDistributionProps {
  itemLabel: string
  shareLabel: string
  rows: ValueDistributionRow[]
  hideValues?: boolean
  disableExpand?: boolean
  onRowClick?: (row: ValueDistributionRow, event: MouseEvent) => void
}

const SHARE_BAR_WIDTH = 80
const EXPAND_WIDTH = 20
const GAP = 8

export default function TableValueDistribution({
  rows,
  itemLabel,
  shareLabel,
  hideValues,
  disableExpand,
  onRowClick,
}: TableValueDistributionProps) {
  const theme = useTheme()

  const canExpand = useMemo(
    () => !disableExpand && rows.some((row) => row.values?.length),
    [rows, disableExpand]
  )

  const dimensions = useMemo(() => {
    const { fontFamily, fontSize, fontWeight } = theme.typography.body2

    return rows.reduce(
      (acc, current) => {
        const formattedPercentage = formatPercentage(current.percentage)
        const percentageWidth = getTextWidth(
          hideValues ? formattedPercentage : `(${formattedPercentage})`,
          fontFamily,
          fontSize,
          fontWeight
        )
        const countWidth = getTextWidth(
          current.count.toString(10),
          fontFamily,
          fontSize,
          fontWeight
        )

        return {
          percentage: percentageWidth > acc.percentage ? percentageWidth : acc.percentage,
          count: countWidth > acc.count ? countWidth : acc.count,
        }
      },
      { percentage: 0, count: 0 }
    )
  }, [hideValues, rows, theme.typography.body2])

  const headerWidth = useMemo(() => {
    let width = SHARE_BAR_WIDTH + GAP

    if (hideValues) {
      width += dimensions.percentage
    } else {
      width += dimensions.percentage + GAP + dimensions.count
    }

    if (canExpand) {
      width += GAP + EXPAND_WIDTH
    }

    return width
  }, [dimensions, hideValues, canExpand])

  const max = useMemo(() => {
    return Math.max(...rows.map((row) => row.percentage))
  }, [rows])

  const [expandedRowIndex, setExpandedRowIndex] = useState<number>(-1)

  const handleToggleRow = useCallback(
    (index: number) => () => {
      if (canExpand) {
        setExpandedRowIndex((prev) => (prev === index ? -1 : index))
      }
    },
    [canExpand]
  )

  return (
    <StyledTable>
      <StyledTHead>
        <StyledRow className={tableValueDistributionClasses.row}>
          <StyledTh className={tableValueDistributionClasses.cell}>{itemLabel}</StyledTh>
          <StyledTh
            className={tableValueDistributionClasses.cell}
            style={{ width: headerWidth }}>
            {shareLabel}
          </StyledTh>
        </StyledRow>
      </StyledTHead>

      {rows.map((row, index) => (
        <Fragment key={index}>
          <StyledTBody
            onClick={handleToggleRow(index)}
            style={{ cursor: canExpand ? 'pointer' : undefined }}>
            <Tooltip
              title={
                disableExpand && row.values && row.values?.length > 1
                  ? row.values
                      .map((value) => `${value.label} (${value.formattedPercentage})`)
                      .join(', ')
                  : undefined
              }
              disableInteractive>
              <StyledRow
                className={clsx(tableValueDistributionClasses.row, {
                  [tableValueDistributionClasses.clickable]: onRowClick,
                })}
                onClick={(event) => onRowClick && onRowClick(row, event)}
                style={{ cursor: onRowClick ? 'pointer' : undefined }}>
                <StyledTd className={tableValueDistributionClasses.cell}>
                  <Stack
                    component={row.href ? Link : 'div'}
                    href={row.href}>
                    <Typography
                      variant={'body2'}
                      noWrap>
                      {row.label}
                    </Typography>

                    {row.href && <ChevronRightIcon fontSize={'inherit'} />}
                  </Stack>
                </StyledTd>

                <StyledTd
                  className={tableValueDistributionClasses.cell}
                  style={{
                    width: hideValues ? dimensions.percentage : dimensions.count,
                    justifyContent: 'end',
                  }}>
                  {hideValues ? row.formattedPercentage : row.count}
                </StyledTd>

                {!hideValues && (
                  <StyledTd
                    className={clsx(
                      tableValueDistributionClasses.cell,
                      tableValueDistributionClasses.textSecondary
                    )}
                    style={{ width: dimensions.percentage }}>
                    ({row.formattedPercentage})
                  </StyledTd>
                )}

                <StyledTd
                  className={tableValueDistributionClasses.cell}
                  style={{ width: SHARE_BAR_WIDTH }}>
                  <Bar
                    color={row.color}
                    percentage={(row.percentage / max) * 100}
                  />
                </StyledTd>

                {canExpand && (
                  <StyledTd
                    className={tableValueDistributionClasses.cell}
                    style={{ width: EXPAND_WIDTH }}>
                    {row?.values?.length && <MenuExpandIcon open={expandedRowIndex === index} />}
                  </StyledTd>
                )}
              </StyledRow>
            </Tooltip>
          </StyledTBody>

          {row?.values?.length && (
            <Collapse in={expandedRowIndex === index}>
              <StyledTBody className={tableValueDistributionClasses.tbodyValues}>
                {row.values.map((value, valueIndex) => (
                  <StyledRow
                    key={valueIndex}
                    className={tableValueDistributionClasses.row}>
                    <StyledTd className={tableValueDistributionClasses.cell}>
                      {value.label}
                    </StyledTd>

                    <StyledTd
                      className={tableValueDistributionClasses.cell}
                      style={{
                        width: hideValues ? dimensions.percentage : dimensions.count,
                        justifyContent: 'end',
                      }}>
                      {hideValues ? value.formattedPercentage : value.count}
                    </StyledTd>

                    {!hideValues && (
                      <StyledTd
                        className={clsx(
                          tableValueDistributionClasses.cell,
                          tableValueDistributionClasses.textSecondary
                        )}
                        style={{
                          width: dimensions.percentage,
                        }}>
                        ({value.formattedPercentage})
                      </StyledTd>
                    )}

                    <StyledTd
                      className={tableValueDistributionClasses.cell}
                      style={{ width: SHARE_BAR_WIDTH }}>
                      <Bar
                        color={value.color || row.color}
                        percentage={(value.percentage / max) * 100}
                      />
                    </StyledTd>
                    <StyledTd style={{ width: EXPAND_WIDTH }} />
                  </StyledRow>
                ))}
              </StyledTBody>
            </Collapse>
          )}
        </Fragment>
      ))}
    </StyledTable>
  )
}
