import { CompanyIcon } from '@gain/components/icons'
import Head from '@gain/modules/head'
import { Asset } from '@gain/rpc/app-model'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import ProfileTabBar, { ProfileTabContainer } from '../../../common/profile-tab-bar'
import CardAssetFte, { useHasFteMeasurements } from '../../../features/asset/asset-fte-card'
import BookmarksListAddAssetButton from '../../../features/bookmarks-list/bookmarks-list-add-assets/bookmarks-list-add-asset-button'
import ButtonAddToDealCloud from '../../../features/dealcloud'
import MobilePageHeader from '../../../layout/mobile-page-header'
import { useAssetPageParams } from '../../utils'
import AssetHeaderSubtitle, { useHasSubtitle } from '../asset-header/asset-header-subtitle'
import ShareFeedbackAlert from '../asset-summary/share-feedback-alert'
import AssetAutomatedDetails from './asset-automated-details'
import AssetAutomatedRequestDetailed from './asset-automated-request-detailed'

export interface AssetAutomated {
  asset: Asset
  isBrowserExtension?: boolean
}

export default function AssetAutomated({ asset, isBrowserExtension }: AssetAutomated) {
  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.only('xs'))
  const isMdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const params = useAssetPageParams()

  const hasFteMeasurements = useHasFteMeasurements(asset)

  const hasSubtitle = useHasSubtitle(asset)

  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name}</title>
      </Head>

      {isXs && !isBrowserExtension && (
        <MobilePageHeader
          actions={
            <BookmarksListAddAssetButton
              assetId={asset.id}
              color={'default'}
              fontSize={24}
              bold
            />
          }
          title={asset.generalInfo?.name}
        />
      )}

      <ProfileTabBar
        defaultIcon={CompanyIcon}
        infoActions={[
          <BookmarksListAddAssetButton
            key={'btn-add-to-list'}
            assetId={asset.id}
          />,
          <ButtonAddToDealCloud
            key={'btn-add-to-dealcloud'}
            assetId={asset.id}
            variant={isBrowserExtension || isMdDown ? 'icon' : 'contained'}
          />,
        ]}
        logoFileUrl={asset.generalInfo?.logoFileUrl || undefined}
        subtitle={hasSubtitle ? <AssetHeaderSubtitle asset={asset} /> : undefined}
        title={
          <Stack
            direction={'row'}
            gap={1}>
            {asset.generalInfo?.name || params.name}

            <Chip
              color={'warning'}
              label={'Generative AI'}
            />
          </Stack>
        }
        replaceUrl
        sticky
      />

      <ProfileTabContainer
        maxWidth={'lg'}
        stickyVariant={'no-tabs'}
        disableGuttersTop>
        <Grid
          spacing={2}
          container>
          <Grid
            xs={12}
            item>
            <AssetAutomatedRequestDetailed asset={asset} />
          </Grid>

          <Grid
            md={6}
            xs={12}
            item>
            <AssetAutomatedDetails
              asset={asset}
              sx={{
                minHeight: {
                  lg: 375,
                },
              }}
            />
          </Grid>

          {hasFteMeasurements && (
            <Grid
              md={6}
              xs={12}
              item>
              <CardAssetFte
                asset={asset}
                showDivider
              />
            </Grid>
          )}

          <Grid
            xs={12}
            item>
            <ShareFeedbackAlert
              assetId={asset.id}
              assetName={asset.generalInfo?.name || ''}
              assetRegion={asset.generalInfo?.headquarters || ''}
            />
          </Grid>
        </Grid>
      </ProfileTabContainer>
    </>
  )
}
