import { useDeleteBookmarksFilteredList } from '@gain/api/app/hooks'
import { BookmarkFilledIcon, BookmarkIcon, CloseIcon } from '@gain/components/icons'
import { AssetListItem, BookmarksFilteredList } from '@gain/rpc/app-model'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { MouseEvent, PointerEvent, useState } from 'react'

import { ASSET_FILTER_MAP, AssetFilterField } from '../../asset/asset-filter-bar'
import BookmarksFilteredCreateDialog from '../../bookmarks-list/bookmarks-list-create/bookmarks-filtered-create-dialog'
import useDeleteOrLeaveCustomList from '../../bookmarks-list/bookmarks-list-edit/use-delete-or-leave-custom-list'
import { FilterModel, fromFilterModel } from '../../filter/filter-bar'

const StyledActionButtons = styled('div')({
  marginLeft: 'auto',
  whiteSpace: 'nowrap',
})

const StyledDeleteIconButton = styled(IconButton)(({ theme }) => ({
  width: 32,
  height: 'inherit',
  color: theme.palette.text.secondary,
  fontSize: theme.typography.body1.fontSize,
  padding: theme.spacing(1, 0.5),
  lineHeight: 0,
}))

const StyledBookmarkIconButton = styled(IconButton)(({ theme }) => ({
  width: 32,
  height: 32,
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

interface RecentFilterActionsProps {
  list: BookmarksFilteredList
  filterModel: FilterModel<AssetListItem, AssetFilterField>

  // recentFilterListId is used to delete the original recent filter list
  // (i.e. if recent filter was replaced by a saved list)
  recentFilterListId: number
}

export default function RecentFilterActions({
  list,
  filterModel,
  recentFilterListId,
}: RecentFilterActionsProps) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const deleteQueryList = useDeleteBookmarksFilteredList()
  const removeList = useDeleteOrLeaveCustomList()

  const handleSaveRecentlyFiltered = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()

    // Create new asset query list if it's not saved, otherwise delete
    if (list.isRecentlyFiltered) {
      setDialogOpen(true)
    } else {
      removeList(list)
    }
  }

  const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    deleteQueryList(recentFilterListId)
  }

  const handlePointerDown = (event: PointerEvent<HTMLButtonElement>) => {
    event.preventDefault() // Prevent search input from losing focus
  }

  return (
    <StyledActionButtons>
      <BookmarksFilteredCreateDialog
        filters={fromFilterModel(filterModel, ASSET_FILTER_MAP)}
        onClose={() => {
          setDialogOpen(false)
        }}
        open={dialogOpen}
        disableRedirect
      />

      <StyledBookmarkIconButton
        color={'primary'}
        onClick={handleSaveRecentlyFiltered}>
        {list.isRecentlyFiltered ? (
          <BookmarkIcon
            fontSize={'inherit'}
            strokeWidth={1}
          />
        ) : (
          <BookmarkFilledIcon
            color={'primary'}
            fontSize={'inherit'}
            strokeWidth={1}
          />
        )}
      </StyledBookmarkIconButton>

      <StyledDeleteIconButton
        color={'inherit'}
        onClick={handleDelete}
        onPointerDown={handlePointerDown}
        size={'small'}>
        <CloseIcon color={'inherit'} />
      </StyledDeleteIconButton>
    </StyledActionButtons>
  )
}
