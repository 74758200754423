import Button, { buttonClasses } from '@mui/material/Button'
import Dialog, { dialogClasses, DialogProps } from '@mui/material/Dialog'
import DialogActions, { dialogActionsClasses } from '@mui/material/DialogActions'
import DialogContent, { dialogContentClasses } from '@mui/material/DialogContent'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import DialogHeader from '../../../common/dialog-header'

const StyledUl = styled('ul')({
  margin: 0,
  padding: '0 1em',
})

const StyledDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 560,
  },

  [`& .${dialogContentClasses.root}`]: {
    padding: theme.spacing(3, 4, 1),
  },

  [`& .${dialogActionsClasses.root}`]: {
    backgroundColor: theme.palette.common.white,
    borderTop: 0,
    justifyContent: 'center',
    padding: theme.spacing(2, 0),
  },

  [`& .${buttonClasses.root}`]: {
    minWidth: 83,
  },
}))

const StyledLi = styled('li')(({ theme }) => ({
  ...theme.typography.body2,
  marginLeft: theme.spacing(0.5),
}))

interface FinancialRevenueExplainerProps {
  isOpen: boolean
  handleClose: () => void
}

export default function FinancialRevenueExplainer({
  isOpen,
  handleClose,
}: FinancialRevenueExplainerProps) {
  return (
    <StyledDialog
      onClose={handleClose}
      open={isOpen}>
      <DialogHeader
        onCloseClick={handleClose}
        title={'AI estimate'}
      />
      <DialogContent>
        <Typography
          sx={{ marginBottom: 1 }}
          variant={'h5'}>
          Methodology
        </Typography>
        <Typography
          sx={{ marginBottom: 3 }}
          variant={'body2'}>
          AI estimates are based on a neural network trained on our company dataset. Predictions are
          made based on various factors including business activity, growth rate and founding year.
          The predicted Revenue/FTE is multiplied by the last available FTE proxy to give an
          estimate for Revenue.
        </Typography>
        <Typography
          sx={{ marginBottom: 1 }}
          variant={'h5'}>
          Accuracy
        </Typography>
        <Typography
          sx={{ marginBottom: 1 }}
          variant={'body2'}>
          Our tests on companies with known revenues show that predictions are accurate within:
        </Typography>
        <StyledUl sx={{ paddingBottom: 1 }}>
          <StyledLi>Factor of 2: 66% of the time</StyledLi>
          <StyledLi>Factor of 3: 85%</StyledLi>
          <StyledLi>Factor of 5: 95%</StyledLi>
        </StyledUl>
        <Typography
          sx={{ marginBottom: 1 }}
          variant={'body2'}>
          2% of predictions are off by a factor of 10 or more. Prediction accuracy increases with
          company size: for companies with over 200 FTE, &lt;1% of predictions are off by a factor
          of 10 or more.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color={'primary'}
          onClick={handleClose}
          variant={'outlined'}
          autoFocus>
          Ok
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}
