import { useAssetList } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { AssetListItem, DealListItem } from '@gain/rpc/app-model'
import { listFilter } from '@gain/rpc/utils'

import { useInvestorPageParams } from '../../utils'
import InvestorDealsList from '../investor-deals-list/investor-deals-list'

export default function RouteAddOns() {
  const { investorId, investorName } = useInvestorPageParams()
  const assets = useAssetList({
    filter: [listFilter<AssetListItem>('ownerIds', '=', investorId)],
    sort: [{ field: 'ebitda', direction: 'desc' }],
    limit: 500,
  })
  const defaultFilters = [
    listFilter<DealListItem>(
      'buyerAssetIds',
      '=',
      assets.data.items.map((item) => item.id)
    ),
  ]

  return (
    <>
      <Head>
        <title>{investorName} - Add-ons</title>
      </Head>
      <InvestorDealsList defaultFilters={defaultFilters} />
    </>
  )
}
