import { useListItems } from '@gain/api/app/hooks'
import { BookmarkIcon, PlusIcon } from '@gain/components/icons'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { MouseEvent, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { BOOKMARKS_CREATE_PATH } from '../../../routes/utils'
import { EmptyListItem } from '../empty-list-item'
import MenuListItem from '../menu-list-item'
import { MenuSubHeader } from '../menu-sub-header'
import { BookmarksListMenuCollapsed } from './bookmarks-list-menu-collapsed'
import BookmarksListMenuItem from './bookmarks-list-menu-item'
import { ListCollapse } from './list-collapse'

const StyledMenuSubHeader = styled(MenuSubHeader)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

const StyledPlusButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(-0.75),
}))

type CustomListMenuProps = {
  onLastUpdatedListIntersection?: (element: Element, inView: boolean) => void
  isOpen: boolean
}

export function BookmarksListMenu({ onLastUpdatedListIntersection, isOpen }: CustomListMenuProps) {
  const plusIconRef = useRef<HTMLButtonElement>(null)
  const items = useListItems()
  const totalUpdates = items.reduce((a, b) => a + b.updatedAssetCount, 0)
  const [anchor, setAnchor] = useState<Element | null>(null)

  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    setAnchor(null)
  }, [location, setAnchor])

  return (
    <>
      <StyledMenuSubHeader
        actions={
          <StyledPlusButton
            ref={plusIconRef}
            color={'primary'}
            edge={'end'}
            onClick={() => history.push(BOOKMARKS_CREATE_PATH)}
            size={'small'}>
            <PlusIcon />
          </StyledPlusButton>
        }
        isOpen={isOpen}
        title={'Bookmarks'}
      />
      <ListCollapse
        collapsed={
          <>
            <MenuListItem
              icon={<BookmarkIcon />}
              iconBadge={totalUpdates}
              label={'Bookmarks'}
              onClick={(event: MouseEvent) => setAnchor(event.target as Element)}
            />
            <BookmarksListMenuCollapsed
              anchorEl={anchor}
              items={items}
              onClose={() => setAnchor(null)}
            />
          </>
        }
        expanded={
          <>
            {items.map((item, i) => {
              // The last updated list item we track its visibility status
              const isLastUpdatedItem =
                item.updatedAssetCount > 0 &&
                (!items[i + 1] || items[i + 1].updatedAssetCount === 0)

              const handleIntersection =
                isLastUpdatedItem && onLastUpdatedListIntersection
                  ? onLastUpdatedListIntersection
                  : undefined

              return (
                <BookmarksListMenuItem
                  key={item.id}
                  list={item}
                  onLastUpdatedListIntersection={handleIntersection}
                />
              )
            })}
            {items.length === 0 && <EmptyListItem message={'No lists available'} />}
          </>
        }
        isOpen={isOpen}
      />
    </>
  )
}
