import {
  useLeaveBookmarksList,
  useShareBookmarksList,
  useTransferBookmarksList,
  useUnshareBookmarksList,
} from '@gain/api/app/hooks'

import BookmarksShareDialog, { BookmarksShareDialogProps } from './bookmarks-share-dialog'

type ShareCustomAssetListDialogProps = Omit<
  BookmarksShareDialogProps,
  'onLeave' | 'onTransfer' | 'onUnshare' | 'onShare'
>

export function BookmarksListShareDialog(props: ShareCustomAssetListDialogProps) {
  const leaveList = useLeaveBookmarksList()
  const transferList = useTransferBookmarksList()
  const shareList = useShareBookmarksList()
  const unshareList = useUnshareBookmarksList()

  return (
    <BookmarksShareDialog
      onLeave={leaveList}
      onShare={shareList}
      onTransfer={transferList}
      onUnshare={unshareList}
      {...props}
    />
  )
}
