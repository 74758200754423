import { useFindSectorCallback } from '@gain/api/app/hooks'
import Tooltip from '@gain/components/tooltip'
import { isNotEmpty } from '@gain/utils/typescript'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import { uniq } from 'lodash'
import { useMemo } from 'react'

import { AssetsPerSector } from './assets-per-sector'

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0, 0.75 / 2),
  '&:first-child': {
    marginLeft: 0,
  },
  '&:last-child': {
    marginRight: 0,
  },
}))

export interface AssetSectorsTableCellProps {
  sectorNames: string[]
  maxItems?: number
  dense?: boolean
}

export function AssetSectorsTableCell({
  sectorNames,
  dense,
  maxItems = 1,
}: AssetSectorsTableCellProps) {
  const findSector = useFindSectorCallback()

  const items = useMemo(
    () =>
      uniq(sectorNames.filter(isNotEmpty)).map(
        (name) =>
          findSector(name) || {
            name: name,
            title: name,
          }
      ),
    [sectorNames, findSector]
  )

  const rest = items.slice(maxItems)

  if (items.length === 0) {
    return <>-</>
  }

  return (
    <>
      {items.slice(0, maxItems).map((sector, index) => (
        <Tooltip
          key={index}
          title={<AssetsPerSector sectorNames={[sector.name]} />}
          variant={'preview'}
          disableInteractive>
          <StyledChip
            key={index}
            label={sector.title}
            size={dense ? 'small' : 'medium'}
          />
        </Tooltip>
      ))}
      {rest.length > 0 && (
        <Tooltip
          title={<AssetsPerSector sectorNames={rest.map((sector) => sector.name)} />}
          variant={'preview'}
          disableInteractive>
          <StyledChip
            label={`+${rest.length}`}
            size={dense ? 'small' : 'medium'}
            variant={'outlined'}
          />
        </Tooltip>
      )}
    </>
  )
}
