import Head from '@gain/modules/head'
import { AssetList, Investor, InvestorStrategy } from '@gain/rpc/app-model'
import Grid from '@mui/material/Grid2'

import InvestorStrategyDescriptionCard from '../../../features/investor-strategy/investor-strategy-description-card'
import InvestorStrategyPreferencesCard from '../../../features/investor-strategy/investor-strategy-preferences-card'
import { useTrackPageView } from '../../../features/planhat/planhat-hooks'
import InvestorFundraisingCard from '../../investor/route-summary/investor-fundraising-card'
import {
  generateInvestorStrategyPagePath,
  INVESTOR_STRATEGY_FUNDS_PAGE_PATH,
  useInvestorStrategyPageParams,
} from '../route-strategy-path'
import InvestorStrategyDealsCard from './investor-strategy-deals-card'
import InvestorStrategyFundsCard from './investor-strategy-funds-card'
import InvestorStrategyMetricsCard from './investor-strategy-metrics-card'
import InvestorStrategyOwnershipCard from './investor-strategy-ownership-card'
import InvestorStrategyRegionCard from './investor-strategy-region-card'
import InvestorStrategySectorCard from './investor-strategy-sector-card'
import InvestorStrategyShareFeedbackAlert from './investor-strategy-share-feedback'

export interface RouteSummaryProps {
  investor: Investor
  strategy: InvestorStrategy
  assets: AssetList
}

export default function RouteSummary({ investor, strategy, assets }: RouteSummaryProps) {
  const params = useInvestorStrategyPageParams()

  useTrackPageView('investor_strategy', {
    id: strategy.id,
    tab: 'summary',
    requireTab: true,
  })

  const hasInformationAndPreferencesCard =
    strategy.description ||
    strategy.investmentTicketSizeMax !== null ||
    strategy.evRangeMin !== null ||
    strategy.evRangeMax !== null ||
    strategy.ebitdaRangeMin !== null ||
    strategy.ebitdaRangeMax !== null ||
    strategy.preferredEquityStakeMinPct !== null ||
    strategy.preferredEquityStakeMaxPct !== null

  return (
    <>
      <Head>
        <title>
          {investor.name} - {strategy.name} - Summary
        </title>
      </Head>

      <Grid
        spacing={2}
        sx={{ alignSelf: 'stretch' }}
        container>
        <Grid size={12}>
          <InvestorStrategyMetricsCard
            assets={assets}
            investor={investor}
            strategy={strategy}
          />
        </Grid>

        {hasInformationAndPreferencesCard && (
          <Grid size={{ xs: 12, lg: 4 }}>
            <InvestorStrategyDescriptionCard strategy={strategy} />
          </Grid>
        )}

        <Grid size={{ xs: 12, lg: hasInformationAndPreferencesCard ? 8 : 4 }}>
          <InvestorStrategyFundsCard
            funds={strategy.funds}
            href={generateInvestorStrategyPagePath(params, INVESTOR_STRATEGY_FUNDS_PAGE_PATH)}
          />
        </Grid>

        {hasInformationAndPreferencesCard && (
          <Grid size={{ xs: 12, lg: 4 }}>
            <InvestorStrategyPreferencesCard
              assets={assets}
              strategy={strategy}
            />
          </Grid>
        )}

        <Grid size={{ xs: 12, lg: 4 }}>
          <InvestorFundraisingCard
            funds={strategy.funds}
            href={generateInvestorStrategyPagePath(params, INVESTOR_STRATEGY_FUNDS_PAGE_PATH)}
          />
        </Grid>

        <Grid size={{ xs: 12, lg: 4 }}>
          <InvestorStrategyDealsCard
            investor={investor}
            strategy={strategy}
          />
        </Grid>

        <Grid size={{ xs: 12, lg: 4 }}>
          <InvestorStrategySectorCard assets={assets.items} />
        </Grid>

        <Grid size={{ xs: 12, lg: 4 }}>
          <InvestorStrategyRegionCard assets={assets.items} />
        </Grid>

        <Grid size={{ xs: 12, lg: 4 }}>
          <InvestorStrategyOwnershipCard
            assets={assets.items}
            investor={investor}
          />
        </Grid>

        <Grid size={12}>
          <InvestorStrategyShareFeedbackAlert
            investorId={investor.id}
            investorName={investor.name}
            strategyId={strategy.id}
            strategyName={strategy.name}
          />
        </Grid>
      </Grid>
    </>
  )
}
