import Head from '@gain/modules/head'
import { AssetList, Investor, InvestorStrategyListItem } from '@gain/rpc/app-model'
import Grid from '@mui/material/Grid2'

import Card, { CardContent, CardHeader } from '../../../common/card/card'
import FteMeasurements from '../../../features/fte-measurements/fte-measurements'
import InvestorStrategyDescriptionCard from '../../../features/investor-strategy/investor-strategy-description-card'
import InvestorStrategyPreferencesCard from '../../../features/investor-strategy/investor-strategy-preferences-card'
import {
  generateInvestorPagePath,
  INVESTOR_PAGE_FUNDS_PATH,
  useInvestorPageParams,
} from '../../utils'
import InvestorDealsCard from './investor-deals-card'
import InvestorFundraisingCard from './investor-fundraising-card'
import InvestorMetricsCard from './investor-metrics-card'
import InvestorOrganisationCard from './investor-organisation-card'
import InvestorPortfolioOwnershipCard from './investor-porfolio-ownership-card'
import InvestorPortfolioRegionCard from './investor-porfolio-region-card'
import InvestorPortfolioSectorCard from './investor-porfolio-sector-card'
import ShareFeedbackAlert from './investor-share-feedback'
import InvestorStrategiesCard from './investor-strategies-card'

interface RouteInvestorProps {
  investor: Investor
  assets: AssetList
  strategies: InvestorStrategyListItem[]
}

export default function RouteSummary({ investor, assets, strategies }: RouteInvestorProps) {
  const params = useInvestorPageParams()

  const isSingleStrategy = investor.strategies.length === 1

  return (
    <Grid
      spacing={2}
      container>
      <Head>
        <title>{investor.name} - Summary</title>
      </Head>

      <Grid size={12}>
        <InvestorMetricsCard
          assets={assets}
          investor={investor}
        />
      </Grid>

      <Grid size={{ xs: 12, lg: 4 }}>
        <InvestorOrganisationCard
          assets={assets}
          investor={investor}
        />
      </Grid>

      {!isSingleStrategy && (
        <Grid size={{ xs: 12, lg: 8 }}>
          <InvestorStrategiesCard
            assets={assets}
            investor={investor}
            strategies={strategies}
          />
        </Grid>
      )}

      <Grid size={{ xs: 12, lg: 4 }}>
        <InvestorFundraisingCard
          funds={investor.funds}
          href={generateInvestorPagePath(params, INVESTOR_PAGE_FUNDS_PATH)}
        />
      </Grid>

      <Grid size={{ xs: 12, lg: 4 }}>
        <InvestorDealsCard investor={investor} />
      </Grid>

      {isSingleStrategy && (
        <Grid size={{ xs: 12, lg: 4 }}>
          <InvestorStrategyDescriptionCard
            strategy={investor.strategies[0]}
            isSingleStrategy
          />
        </Grid>
      )}

      {!isSingleStrategy && (
        <Grid size={{ xs: 12, lg: 4 }}>
          <Card
            sx={{ minHeight: 288 }}
            fullHeight>
            <CardHeader title={'FTEs'} />
            <CardContent sx={{ flexGrow: 1 }}>
              <FteMeasurements fteMeasurements={investor.fteMeasurements} />
            </CardContent>
          </Card>
        </Grid>
      )}

      <Grid size={{ xs: 12, lg: 4 }}>
        <InvestorPortfolioRegionCard assets={assets.items} />
      </Grid>

      <Grid size={{ xs: 12, lg: 4 }}>
        <InvestorPortfolioSectorCard assets={assets.items} />
      </Grid>

      {isSingleStrategy && (
        <Grid size={{ xs: 12, lg: 4 }}>
          <InvestorStrategyPreferencesCard
            assets={assets}
            strategy={investor.strategies[0]}
          />
        </Grid>
      )}

      <Grid size={{ xs: 12, lg: 4 }}>
        <InvestorPortfolioOwnershipCard
          assets={assets.items}
          investor={investor}
        />
      </Grid>

      {isSingleStrategy && (
        <Grid size={{ xs: 12, lg: 4 }}>
          <Card
            sx={{ minHeight: 288 }}
            fullHeight>
            <CardHeader title={'FTEs'} />
            <CardContent sx={{ flexGrow: 1 }}>
              <FteMeasurements fteMeasurements={investor.fteMeasurements} />
            </CardContent>
          </Card>
        </Grid>
      )}

      <Grid size={12}>
        <ShareFeedbackAlert
          investorId={investor.id}
          investorName={investor.name}
        />
      </Grid>
    </Grid>
  )
}
