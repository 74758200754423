import { UserIcon } from '@gain/components/icons'
import { AssetManager, ObjectType } from '@gain/rpc/app-model'
import { formatAssetManager } from '@gain/utils/asset'
import { formatManagerPosition } from '@gain/utils/manager'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

import ContentLink from '../../../../common/content-link'
import LinkedInButton from '../../../../common/linked-in-button/linked-in-button'
import RequestEmailButton from '../../../../features/request-email-button/request-email-button'

const StyledContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: 36,
})

interface AssetOwnershipManagerProps {
  manager: AssetManager | null
}

export default function AssetOwnershipManager({ manager }: AssetOwnershipManagerProps) {
  if (!manager) {
    return null
  }

  return (
    <StyledContainer>
      <Stack
        alignItems={'center'}
        direction={'row'}
        spacing={1}
        sx={{ minWidth: 0 }}>
        <ContentLink
          avatarProps={{
            children: <UserIcon />,
          }}
          href={null}
          label={formatAssetManager(manager)}
          labelTypographyProps={{ disableTooltip: false }}
          region={manager.person?.locationRegion}
        />
        <Chip
          label={formatManagerPosition(manager.managementPosition, { variant: 'short' })}
          size={'small'}
        />
      </Stack>
      <Stack
        direction={'row'}
        sx={{ mr: -1 }}>
        {manager.person && (
          <RequestEmailButton
            objectId={manager.assetId}
            objectType={ObjectType.Asset}
            person={manager.person}
          />
        )}
        {manager.person?.linkedInUrl && <LinkedInButton url={manager.person.linkedInUrl} />}
      </Stack>
    </StyledContainer>
  )
}
