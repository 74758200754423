import { useIsBrowserExtension } from '@gain/modules/browser-extension'
import { Asset } from '@gain/rpc/app-model'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

import Card, { CardContent, CardHeader, CardProps } from '../../../common/card/card'
import AssetBusinessDescription from '../asset-summary/asset-business-card/asset-business-description'
import AssetBusinessDetails from '../asset-summary/asset-business-card/asset-business-details'
import AssetTags from '../asset-summary/asset-business-card/asset-tags'

export type AssetDetailsProps = CardProps & {
  asset: Asset
}

const StyledBrowserExtensionAssetBusinessDetails = styled(AssetBusinessDetails)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 50%)',
  columnGap: theme.spacing(2),
}))

export default function AssetAutomatedDetails({ asset, sx, ...rest }: AssetDetailsProps) {
  const isBrowserExtension = useIsBrowserExtension()

  return (
    <Card
      {...rest}
      sx={sx}>
      <CardHeader title={'Business'} />
      <CardContent
        sx={{
          flex: asset.description?.atAGlance ? 1 : 'initial',
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0,
        }}>
        <Stack
          direction={'column'}
          spacing={0}
          sx={{ flex: 1, minHeight: 0, pb: 0 }}>
          {!isBrowserExtension && (
            <>
              <AssetBusinessDescription
                asset={asset}
                maxLines={100}
                sx={{ mt: 0, flex: asset.description?.atAGlance ? 1 : 0 }}
                disableAutoMaxLines
              />

              <AssetBusinessDetails
                asset={asset}
                sx={{ py: 2, flex: 0 }}
              />
            </>
          )}

          {isBrowserExtension && <StyledBrowserExtensionAssetBusinessDetails asset={asset} />}
        </Stack>

        {!isBrowserExtension && asset.tags && asset.tags.length > 0 && (
          <AssetTags
            assetTags={asset.tags}
            maxLines={20}
          />
        )}
      </CardContent>
    </Card>
  )
}
