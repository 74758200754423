import Head from '@gain/modules/head'
import { Investor } from '@gain/rpc/app-model'

import RepresentativesCard from '../../../features/representatives/representatives-card'
import { generateInvestorPagePortfolioTabPath, useInvestorPageParams } from '../../utils'

interface RouteRepresentativesProps {
  investor: Investor
}

export default function RouteRepresentatives({ investor }: RouteRepresentativesProps) {
  const params = useInvestorPageParams()

  return (
    <>
      <Head>
        <title>{params.investorName} - Representatives</title>
      </Head>

      <RepresentativesCard
        investor={investor}
        portfolioPath={generateInvestorPagePortfolioTabPath(params)}
      />
    </>
  )
}
