import Flag from '@gain/components/flag'
import { InvestorListItem } from '@gain/rpc/app-model'
import { formatFte } from '@gain/utils/financials'
import { ratingEsg, RatingTableCell } from '@gain/utils/investment-criteria'
import { formatNumber } from '@gain/utils/number'

import EbitdaValuesRange from '../../../common/ebitda-values-range'
import { ColumnConfig } from '../../../common/virtual-table'
import {
  NameVirtualTableCell,
  NameVirtualTableHeaderCell,
} from '../../../common/virtual-table/cells'
import { AssetRegionsTableCell } from '../../asset/asset-regions-table-cell'
import { AssetSectorsTableCell } from '../../asset/asset-sectors-table-cell'
import FinancialRangeValue from '../../financial/financial-range-value'
import FinancialValue from '../../financial/financial-value'
import { InvestorListItemAssetsTotalTableCell } from './investor-list-item-assets-total-table-cell'
import { InvestorListItemFlagshipFundNameTableCell } from './investor-list-item-flagship-fund-name-table-cell'

export const investorListItemTableColumns: ColumnConfig<InvestorListItem>[] = [
  {
    field: 'name',
    headerName: 'Investor',
    align: 'left',
    width: 272,
    sticky: true,
    flex: 1,
    renderCell: ({ row }) => (
      <NameVirtualTableCell
        logoFileUrl={row.logoFileUrl}
        name={row.name}
      />
    ),
    renderHeader: NameVirtualTableHeaderCell,
  },
  {
    field: 'assetsTotal',
    headerName: 'Companies',
    align: 'right',
    width: 136,
    sortFields: ['assetsFiltered'],
    renderCell: (params) => <InvestorListItemAssetsTotalTableCell {...params} />,
  },
  {
    field: 'fundsRaisedLastFiveYearsEur',
    headerName: 'Fundraising (L5Y)',
    align: 'right',
    width: 168,
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'flagshipFundSizeEur',
    headerName: 'Flagship fund size',
    align: 'right',
    width: 160,
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'flagshipFundName',
    headerName: 'Flagship fund',
    width: 248,
    sortFields: ['flagshipFundVintageDate'],
    renderCell: ({ row, value }) => (
      <InvestorListItemFlagshipFundNameTableCell
        value={value}
        year={row.flagshipFundVintageYear}
      />
    ),
  },
  {
    field: 'dryPowderMinEur',
    headerName: 'Drypowder estimate',
    align: 'right',
    width: 184,
    renderCell: (params) => (
      <FinancialRangeValue
        max={params.row.dryPowderMaxEur}
        min={params.row.dryPowderMinEur}
      />
    ),
  },
  {
    field: 'liveFundsCount',
    headerName: 'Live funds',
    align: 'right',
    width: 192,
  },
  {
    field: 'liveFundSizeEur',
    headerName: 'Live fund size',
    align: 'right',
    width: 168,
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'dealsTotalLastFiveYears',
    headerName: 'Deals (L5Y)',
    width: 160,
    valueFormatter: ({ value }) => formatNumber(value),
  },
  {
    field: 'dealsEntriesTotalLastFiveYears',
    headerName: 'Entries (L5Y)',
    width: 128,
    valueFormatter: ({ value }) => formatNumber(value),
  },
  {
    field: 'dealsExitTotalLastFiveYears',
    headerName: 'Exits (L5Y)',
    width: 128,
    valueFormatter: ({ value }) => formatNumber(value),
  },
  {
    field: 'dealsAddOnsTotalLastFiveYears',
    headerName: 'Add-ons (L5Y)',
    width: 144,
    valueFormatter: ({ value }) => formatNumber(value),
  },
  {
    field: 'operationalHqCountryCode',
    headerName: 'Investor HQ',
    align: 'center',
    width: 111,
    renderCell: (params) =>
      params.row.operationalHqCountryCode ? (
        <Flag code={params.row.operationalHqCountryCode} />
      ) : (
        <>-</>
      ),
  },
  {
    field: 'fte',
    headerName: 'Investor FTEs',
    align: 'right',
    width: 144,
    valueFormatter: ({ value }) => (value ? formatFte(value, false) : '-'),
  },
  {
    field: 'assetEbitdasEur',
    headerName: 'Company EBITDA range',
    align: 'right',
    width: 208,
    sortable: false,
    renderCell: ({ value }) => <EbitdaValuesRange ebitdaValues={value} />,
  },
  {
    field: 'assetEbitdaMedianEur',
    headerName: 'Median EBITDA',
    headerExplainer:
      'Median EBITDA is calculated using only positive EBITDA values, to give a better representation of typical investment size',
    align: 'right',
    width: 168,
    sortFields: ['assetEbitdaMedianEur'],
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'assetRegions',
    headerName: 'Active in region',
    width: 216,
    sortable: false,
    renderCell: ({ row }) => (
      <AssetRegionsTableCell
        regionNames={row.assetRegions}
        disableCounts
      />
    ),
  },
  {
    field: 'assetSectors',
    headerName: 'Active in sector',
    width: 216,
    sortable: false,
    renderCell: ({ row }) => <AssetSectorsTableCell sectorNames={row.assetSectors} />,
  },
  {
    field: 'assetsMedianMarketSegmentRatingOverall',
    headerName: 'Overall ESG risk',
    align: 'left',
    width: 168,
    renderCell: (params) => (
      <RatingTableCell
        criteria={ratingEsg}
        rating={params.value}
      />
    ),
  },
  {
    field: 'assetsMedianMarketSegmentRatingEnvironmental',
    headerName: 'Environmental risk',
    align: 'left',
    width: 168,
    renderCell: (params) => (
      <RatingTableCell
        criteria={ratingEsg}
        rating={params.value}
      />
    ),
  },
  {
    field: 'assetsMedianMarketSegmentRatingSocial',
    headerName: 'Social risk',
    align: 'left',
    width: 168,
    renderCell: (params) => (
      <RatingTableCell
        criteria={ratingEsg}
        rating={params.value}
      />
    ),
  },
]

export const investorTableColumnNames = investorListItemTableColumns
  .map(({ field }) => field)
  .filter(Boolean) as (keyof InvestorListItem)[]
