import { useAppInfiniteListSwr } from '@gain/api/swr'
import Flag from '@gain/components/flag'
import OverflowContainer, { overflowContainerClasses } from '@gain/components/overflow-container'
import { AssetListItem, Investor, ObjectType, PersonListItem } from '@gain/rpc/app-model'
import { listFilter, listFilters, listSort, listSourceFilter } from '@gain/rpc/utils'
import { useElementWidthEffect } from '@gain/utils/dom'
import { formatSubsector } from '@gain/utils/sector'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { useCallback, useMemo, useRef, useState } from 'react'

import Card, { CardHeader } from '../../common/card/card'
import LinkedInButton from '../../common/linked-in-button/linked-in-button'
import Tag from '../../common/tag'
import VirtualTable, { createVirtualTableColumns } from '../../common/virtual-table'
import AssetContentLink from '../asset/asset-content-link'
import { filterModelToQueryString, filterValueGroup, filterValueItem } from '../filter/filter-bar'
import RequestEmailButton from '../request-email-button/request-email-button'

const StyledFlagsContainer = styled('div')({
  [`& .${overflowContainerClasses.visible}`]: {
    display: 'flex',
    alignItems: 'center',
  },
})

type ColumnSizing = 'large' | 'medium' | 'small'

function useColumns(
  investorId: number,
  sizing: ColumnSizing,
  portfolioPath: string,
  assetsIds?: number[]
) {
  return useMemo(() => {
    return createVirtualTableColumns<PersonListItem>(
      {
        field: 'fullName',
        headerName: 'Name',
        width: 70,
        flex: 1,
        sortable: false,
        renderCell: ({ value }) => value,
      },
      {
        field: 'linkedInUrl',
        headerName: 'Contact',
        width: 88,
        sortable: false,
        renderHeader: ({ colDef }) => (sizing !== 'small' ? colDef.headerName : ''),
        renderCell: ({ value, row }) => {
          return (
            <Stack
              flexDirection={'row'}
              gap={0.25}
              marginLeft={-0.75}>
              <RequestEmailButton
                objectId={investorId}
                objectType={ObjectType.Investor}
                person={row}
              />
              <LinkedInButton url={value} />
            </Stack>
          )
        },
      },
      sizing !== 'small' && {
        field: 'regions',
        headerName: 'Regions',
        sortable: false,
        renderCell: ({ value }) => {
          return (
            <StyledFlagsContainer>
              <OverflowContainer maxLines={1}>
                {value.map((region) => (
                  <Flag
                    key={region}
                    code={region}
                  />
                ))}
              </OverflowContainer>
            </StyledFlagsContainer>
          )
        },
        width: 90,
      },
      sizing === 'large' && {
        field: 'subsectors',
        headerName: 'Sectors',
        width: 100,
        flex: 1,
        sortable: false,
        renderCell: ({ value }) => {
          return (
            <OverflowContainer
              lineHeight={16}
              maxLines={1}>
              {value.map((subsector) => (
                <Tag
                  key={subsector}
                  href={[
                    portfolioPath,
                    filterModelToQueryString<AssetListItem, 'subsector'>([
                      filterValueGroup(filterValueItem('subsector', [subsector])),
                    ]),
                  ].join('?')}
                  label={formatSubsector(subsector) as string}
                />
              ))}
            </OverflowContainer>
          )
        },
      },
      sizing !== 'small' && {
        field: 'assets',
        headerName: 'Portfolio',
        width: 100,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => {
          const rowAssets = assetsIds
            ? row.assets.filter((asset) => assetsIds.includes(asset.id))
            : row.assets

          return (
            <OverflowContainer
              lineHeight={24}
              maxLines={1}
              spacing={1}>
              {rowAssets.map((asset) => (
                <AssetContentLink
                  key={asset.id}
                  id={asset.id}
                  logoFileUrl={asset.logoFileUrl}
                  variant={'logo'}
                />
              ))}
            </OverflowContainer>
          )
        },
      }
    )
  }, [assetsIds, investorId, portfolioPath, sizing])
}

interface RepresentativesCardProps {
  investor: Investor
  portfolioPath: string
  assetsIds?: number[]
}

export default function RepresentativesCard({
  investor,
  portfolioPath,
  assetsIds,
}: RepresentativesCardProps) {
  const [columnSizing, setColumnSizing] = useState<ColumnSizing>('large')
  const columns = useColumns(investor.id, columnSizing, portfolioPath, assetsIds)

  const swrPersonsInfinite = useAppInfiniteListSwr('data.listPersons', {
    filter: listFilters<PersonListItem>(
      listSourceFilter('id', { source: 'investorManagers', id: investor.id }),
      assetsIds && listFilter<PersonListItem>('assetIds', '=', assetsIds)
    ),
    sort: [listSort('assetCount', 'desc'), listSort('firstName', 'asc')],
    limit: 50, // smaller page size for faster initial rendering
  })

  const tableRef = useRef<HTMLDivElement>(null)

  const handleWidthChange = useCallback((width: number) => {
    setColumnSizing(width > 750 ? 'large' : width > 530 ? 'medium' : 'small')
  }, [])

  useElementWidthEffect(tableRef, handleWidthChange)

  return (
    <Card
      ref={tableRef}
      sx={{ flex: 1, width: '100%', display: 'flex', flexDirection: 'column', pb: 0 }}>
      <CardHeader title={'Representatives'} />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          flex: 1,
          height: '100%',
        }}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            minHeight: 0,
            maxHeight: '100%',
            flex: 1,
            minWidth: 0,
            maxWidth: '100%',
            display: 'flex',
          }}>
          <VirtualTable
            columns={columns}
            loadingInitial={swrPersonsInfinite.isLoadingInitial}
            loadingMore={swrPersonsInfinite.isLoadingMore}
            onLoadMore={swrPersonsInfinite.fetchMore}
            RowComponentProps={{
              hover: false,
            }}
            rows={swrPersonsInfinite.data?.flatMap((page) => page.items) || []}
            totalSize={swrPersonsInfinite.data?.[0]?.counts.filtered}
            variant={'inline'}
          />
        </div>
      </div>
    </Card>
  )
}
