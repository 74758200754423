import { useDialogState } from '@gain/utils/dialog'

import ShareFeedbackBar from '../../../../common/share-feedback-bar'
import InvestorStrategyShareFeedbackDialog from './investor-strategy-share-feedback-dialog'
import InvestorStrategyShareFeedbackInfoDialog from './investor-strategy-share-feedback-info'

export interface InvestorStrategyShareFeedbackAlertProps {
  investorId: number
  investorName: string
  strategyId: number
  strategyName: string
}

export default function InvestorStrategyShareFeedbackAlert({
  investorId,
  investorName,
  strategyId,
  strategyName,
}: InvestorStrategyShareFeedbackAlertProps) {
  const [shareFeedbackDialogOpen, handleOpenFeedbackDialog, handleCloseFeedbackDialog] =
    useDialogState()
  const [shareFeedbackInfoDialogOpen, handleOpenInfoDialog, handleCloseInfoDialog] =
    useDialogState()

  return (
    <>
      <InvestorStrategyShareFeedbackDialog
        investorId={investorId}
        investorName={investorName}
        onClose={handleCloseFeedbackDialog}
        open={shareFeedbackDialogOpen}
        strategyId={strategyId}
        strategyName={strategyName}
      />

      <InvestorStrategyShareFeedbackInfoDialog
        onClose={handleCloseInfoDialog}
        open={shareFeedbackInfoDialogOpen}
      />

      <ShareFeedbackBar
        onOpenFeedbackDialog={handleOpenFeedbackDialog}
        onOpenInfoDialog={handleOpenInfoDialog}
      />
    </>
  )
}
