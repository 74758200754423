import {
  useAssetList,
  useInvestor,
  useInvestorStrategy,
  useRelatedDealAdvisors,
} from '@gain/api/app/hooks'
import { BriefcaseMoneyIcon, ChevronRightIcon } from '@gain/components/icons'
import { SlotHome, SlotName } from '@gain/components/slot'
import Head from '@gain/modules/head'
import { AssetSourceType } from '@gain/rpc/app-model'
import { isJsonRpcError, listFilter, listSort, RpcErrorCode } from '@gain/rpc/utils'
import { useVisibleContainerHeight } from '@gain/utils/dom'
import { useIsXs } from '@gain/utils/responsive'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Suspense } from 'react'
import { generatePath, Redirect, Route, Switch, useHistory } from 'react-router-dom'

import Loading from '../../common/loading'
import ProfileTabBar, { ProfileTabContainer, useActiveTab } from '../../common/profile-tab-bar'
import { INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL } from '../../features/investor-strategy/investor-strategy-contants'
import MobilePageHeader from '../../layout/mobile-page-header'
import NotFound from '../not-found'
import { generateInvestorPagePath, INVESTOR_PAGE_SUMMARY_PATH } from '../utils'
import RouteAddOns from './route-add-ons'
import RouteAdvisors from './route-advisors'
import RouteAssessment from './route-assessment'
import RouteBenchmarking from './route-benchmarking'
import RouteCompanies from './route-companies'
import RouteEntries from './route-entries'
import RouteExits from './route-exits'
import RouteFunds from './route-funds'
import RouteRepresentatives from './route-representatives'
import { useStrategyTabs } from './route-strategy-hooks'
import {
  INVESTOR_STRATEGY_ADD_ONS_PAGE_PATH,
  INVESTOR_STRATEGY_ADVISORS_PATH,
  INVESTOR_STRATEGY_ASSESSMENT_PAGE_PATH,
  INVESTOR_STRATEGY_ASSETS_PAGE_PATH,
  INVESTOR_STRATEGY_BENCHMARKING_PAGE_PATH,
  INVESTOR_STRATEGY_ENTRIES_PAGE_PATH,
  INVESTOR_STRATEGY_EXITS_PAGE_PATH,
  INVESTOR_STRATEGY_FUNDS_PAGE_PATH,
  INVESTOR_STRATEGY_REPRESENTATIVES_PATH,
  INVESTOR_STRATEGY_SUMMARY_PAGE_PATH,
  useInvestorStrategyPageParams,
} from './route-strategy-path'
import RouteSummary from './route-summary'

const StyledInvestorNameTypography = styled(Typography)(({ theme }) => ({
  transition: theme.transitions.create(['color'], {
    duration: theme.transitions.duration.shortest,
    easing: theme.transitions.easing.easeInOut,
  }),
  willChange: 'color',

  '&:hover': {
    color: theme.palette.text.primary,
  },
}))

const StyledProfileTabContainer = styled(ProfileTabContainer)({
  flex: 1,
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
})

export default function RouteInvestorStrategy() {
  const [containerRef, availableHeight] = useVisibleContainerHeight<HTMLDivElement>()
  const params = useInvestorStrategyPageParams()
  const history = useHistory()
  const isXs = useIsXs()

  const swrInvestor = useInvestor({
    id: params.investorId,
    preview: params.preview,
  })

  // TODO:: Food for thought: add the investor through populate to the strategy?
  const swrInvestorStrategy = useInvestorStrategy({
    id: params.strategyId,
  })

  const swrAssets = useAssetList(() => {
    // don't fetch assets if the investor is still loading
    if (!swrInvestor.data || !swrInvestorStrategy.data) {
      return null
    }

    return {
      page: 0,
      limit: 500,
      sort: [listSort('ebitdaEur', 'desc')],
      filter: [listFilter('strategyIds', '=', swrInvestorStrategy.data.id)],
    }
  })

  const swrDealAdvisors = useRelatedDealAdvisors({
    source: AssetSourceType.InvestorStrategy,
    id: params.strategyId,
  })

  const loading = swrInvestorStrategy.loading || swrInvestor.loading || swrDealAdvisors.loading
  const tabs = useStrategyTabs({
    hasAdvisors: Boolean(swrDealAdvisors.data?.length),
    hasRepresentatives: Boolean(swrAssets.data?.items.length),
  })
  const activeTab = useActiveTab(tabs)

  if (loading || !swrInvestor.data || !swrInvestorStrategy.data) {
    return <Loading />
  }

  if (
    isJsonRpcError(swrInvestorStrategy.error) &&
    swrInvestorStrategy.error.code === RpcErrorCode.InvalidResourceIdentifier
  ) {
    return <NotFound />
  }

  const title = [
    swrInvestor.data.name || '',
    swrInvestorStrategy.data.name ||
      params.strategyName ||
      INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL,
  ].join(' - ')

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      {isXs && <MobilePageHeader title={title} />}

      <ProfileTabBar
        activeTab={activeTab}
        defaultIcon={BriefcaseMoneyIcon}
        logoFileUrl={swrInvestor.data.logoFileUrl}
        onLogoClick={() => {
          history.push(generateInvestorPagePath(params, INVESTOR_PAGE_SUMMARY_PATH))
        }}
        subtitleUrl={swrInvestor.data.url}
        tabActions={<SlotHome slotName={SlotName.Tabs} />}
        tabs={tabs}
        title={
          <Stack
            alignItems={'center'}
            direction={'row'}>
            <StyledInvestorNameTypography
              color={'text.secondary'}
              onClick={(event) => {
                event.stopPropagation()

                history.push(generateInvestorPagePath(params, INVESTOR_PAGE_SUMMARY_PATH))
              }}
              variant={'inherit'}>
              {swrInvestor.data.name || params.investorName}
            </StyledInvestorNameTypography>
            &nbsp;
            <ChevronRightIcon fontSize={'inherit'} />
            &nbsp;
            <Typography variant={'inherit'}>{swrInvestorStrategy.data.name}</Typography>
          </Stack>
        }
        disableIndicatorAnimation
        replaceUrl
        sticky
      />

      <StyledProfileTabContainer
        ref={containerRef}
        maxWidth={'lg'}
        disableGuttersTop>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route
              path={INVESTOR_STRATEGY_SUMMARY_PAGE_PATH}
              exact>
              <RouteSummary
                assets={swrAssets.data}
                investor={swrInvestor.data}
                strategy={swrInvestorStrategy.data}
              />
            </Route>

            <Route
              path={INVESTOR_STRATEGY_ASSETS_PAGE_PATH}
              exact>
              <RouteCompanies investor={swrInvestor.data} />
            </Route>
            <Route
              path={INVESTOR_STRATEGY_FUNDS_PAGE_PATH}
              exact>
              <RouteFunds
                investor={swrInvestor.data}
                strategy={swrInvestorStrategy.data}
              />
            </Route>
            <Route
              path={INVESTOR_STRATEGY_BENCHMARKING_PAGE_PATH}
              exact>
              <RouteBenchmarking investor={swrInvestor.data} />
            </Route>
            <Route
              path={INVESTOR_STRATEGY_ASSESSMENT_PAGE_PATH}
              exact>
              <RouteAssessment
                investor={swrInvestor.data}
                strategy={swrInvestorStrategy.data}
              />
            </Route>
            <Route
              path={INVESTOR_STRATEGY_EXITS_PAGE_PATH}
              exact>
              <RouteExits
                investor={swrInvestor.data}
                strategy={swrInvestorStrategy.data}
              />
            </Route>
            <Route
              path={INVESTOR_STRATEGY_ENTRIES_PAGE_PATH}
              exact>
              <RouteEntries
                investor={swrInvestor.data}
                strategy={swrInvestorStrategy.data}
              />
            </Route>
            <Route
              path={INVESTOR_STRATEGY_ADD_ONS_PAGE_PATH}
              exact>
              <RouteAddOns
                investor={swrInvestor.data}
                strategy={swrInvestorStrategy.data}
              />
            </Route>
            <Route
              path={INVESTOR_STRATEGY_ADVISORS_PATH}
              exact>
              <RouteAdvisors
                availableHeight={availableHeight}
                dealAdvisors={swrDealAdvisors.data ?? []}
              />
            </Route>
            <Route
              path={INVESTOR_STRATEGY_REPRESENTATIVES_PATH}
              exact>
              <RouteRepresentatives
                assets={swrAssets.data}
                investor={swrInvestor.data}
                strategy={swrInvestorStrategy.data}
              />
            </Route>
            <Route path={'*'}>
              <Redirect to={generatePath(INVESTOR_STRATEGY_SUMMARY_PAGE_PATH, params)} />
            </Route>
          </Switch>
        </Suspense>
      </StyledProfileTabContainer>
    </>
  )
}
